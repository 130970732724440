import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import XLSX from 'xlsx'


const MyDropzone = props => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
 
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
      // Do whatever you want with the file contents
        const binaryStr = reader.result
        console.log(reader)
        console.log(binaryStr)

        var data = new Uint8Array(binaryStr);
        var arr = [];
        for(var i = 0; i !== data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");

        /* Call XLSX */
        var workbook = XLSX.read(bstr, {type:"binary", cellDates: true});

        /* DO SOMETHING WITH workbook HERE */
        var first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        var worksheet = workbook.Sheets[first_sheet_name];

        var range = XLSX.utils.decode_range(workbook.Sheets[first_sheet_name]['!ref']);
        range.s.c = 0; // 0 == XLSX.utils.decode_col("A")
        range.e.c = 13; // 6 == XLSX.utils.decode_col("G")
        var new_range = XLSX.utils.encode_range(range);

        console.log(XLSX.utils.sheet_to_json(worksheet, {
          raw:true,
          defval: null, 
          dateNF: "dd/mm/yyyy hh:mm:ss",
          range: new_range
        }));
      }
      reader.readAsArrayBuffer(file)
    })
    
  }, [])

  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop,
    accept: '.xlsx, .csv'
  })

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));



  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside>
        <h4>Selected Files</h4>
        <ul>{files}</ul>
      </aside>
    </section>
  )
}

export default MyDropzone