import React, { useState } from 'react';
import axios from 'axios';

import auth from '../helpers/auth';
import response from '../helpers/responseChecker';
import { External_URLs } from '../helpers/externalUrls';
import { LOGIN_ENDPOINT } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userCredentials, setUserCredentials] = useState({
    username: '',
    password: '',
  });

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserCredentials({
      ...userCredentials,
      [name]: value.replace(/\s/g, ''),
    });
  };

  // SEND STATE VARIABLES' VALUES TO THE API
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(LOGIN_ENDPOINT.login, userCredentials)
      .then(({ data }) => {
        setLoading(false);
        const validator = response.validate(data.code[0], data.message[0]);

        if (validator) {
          auth.login(
            data.content[0].access_token,
            data.content[0].Business_ID,
            data.content[0].User_ID,
            data.content[0].FullName,
            data.content[0].User_Name,
            data.content[0].User_Role,

            // REDIRECT USER TO CASE MANAGEMENT PAGE IF CREDENTIALS ARE RIGHT
            () => {
              props.history.push('/');
            }
          );
        } else {
          setErrorMsg(data.message[0]);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.code[0] === RESPONSE_CODES.httpBadRequest) {
          setLoading(false);
          setErrorMsg(error.response.data.message[0]);
        }
      });
  };

  return (
    <>
      <header className="login-header">
        <div className="container login-container">
          <a href={External_URLs.mainSite}>
            <img
              src={require('../assets/images/brand-logo-white.png')}
              className="login-logo"
              alt="login-logo"
            />
          </a>
        </div>
      </header>

      <div className="login">
        <div className="container login-container">
          <div className="login-wrapper">
            <div className="brand-wrapper">
              <p className="platform-text">OSPREE PLATFORM</p>
              <h2 className="login-text">
                Tested and proven, ready to use on day one.
              </h2>
              <a href={External_URLs.mainSite} className="read-more">
                Read more{' '}
                <img
                  src={require('../assets/images/chevrons-right.svg')}
                  className="icon"
                  alt="right chevrons"
                />
              </a>
            </div>
            <div className="form-wrapper">
              <div className="form-content">
                <h1 className="login-title">Welcome to Ospree</h1>
                <p className="login-description">Login to your account</p>
                <form className="form" onSubmit={handleSubmit}>
                  <label htmlFor="username" className="subtitle">
                    Email Address
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    onChange={handleData}
                  />
                  <label htmlFor="password" className="subtitle">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={handleData}
                  />
                  <button
                    type="submit"
                    className="btn-custom btn-blue btn-hover-violet"
                  >
                    {loading ? 'Loading...' : 'Login'}
                  </button>
                  <p className="error-message">{errorMsg}</p>
                </form>
                <p className="footer-form-text">
                  Don't have an account?
                  <a href={External_URLs.getStarted}>
                    {' '}
                    Start a free trial today.
                  </a>
                </p>
              </div>
              <a
                href={External_URLs.privacyPolicy}
                className="privacy-terms-link"
              >
                Privacy Policy • Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
