import React, { useState, useCallback, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Modal, ModalBody } from 'reactstrap';
import { Context } from '../contexts/Context';
import { saveAs } from 'file-saver';
import classnames from 'classnames';

import moment from 'moment';
import axios from 'axios';
import XLSX from 'xlsx';
import auth from '../helpers/auth';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { Dates_Calendar } from '../helpers/datesCalendar';
import { RESPONSE_CODES } from '../helpers/httpCodes';

import uploadIcon from '../assets/images/icon-upload.svg';

const UploadOption = ({ content }) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    parentImportModule,
    newCase,
    updateGlobalNotifToggler,
    updateIsLoadingState,
    updateUploadModal,
    updateNewCase,
    dropzoneModal,
    toggleDropZone,
    closeDropZone,
    isLoading,
  } = useContext(Context);

  const history = useHistory();

  const exportableFile = [
    {
      obj: [
        { Address: '0x48AaaE9001F1364A1D0B36E9F0A625cD69155b3D' },
        { Address: '35hK24tcLEWcgNA4JxpvbkNkoAcDGqQPsP' },
        { Address: '3KZ526NxCVXbKwwP66RgM3pte6zW4gY1tD' },
      ],
      header: ['Address'],
      filename: 'ospree_import_address.xlsx',
    },
    {
      obj: [
        {
          'Unique Id': 'ID0001',
          'Entity Type': '1',
          'First Name': 'John',
          'Last Name': 'Smith',
          'Date of Birth': '1945-03-01',
          'Country Location': 'SG',
          Nationality: 'US',
        },
        {
          'Unique Id': 'ID0002',
          'Entity Type': '2',
          'Business Name': 'Company Name',
          'Country Location': 'JP',
          'Registered Country': 'SG',
        },
      ],
      header: [
        'Unique Id',
        'Entity Type',
        'First Name',
        'Last Name',
        'Business Name',
        'Date of Birth',
        'Country Location',
        'Nationality',
        'Registered Country',
      ],
      filename: 'ospree_import_case.xlsx',
    },
    {
      obj: [
        {
          Transaction:
            '30710defb0de6fc69b168020be5b2ebe88846669e55b96ef8cdc6295187db036',
        },
        {
          Transaction:
            '3f2b9436ec6f8a94cdf68f461d713d76b78edd9244dfacb284b9bc565956df62',
        },
        {
          Transaction:
            '0x35b4a3e6e20eecf64df999abff906c1558752fd2d8cef3c6b9e296b8c18258c6',
        },
      ],
      header: ['Transaction'],
      filename: 'ospree_import_transaction.xlsx',
    },
  ];

  const [rejectedFile, setRejectedFile] = useState(false);
  const [caseData, setCaseData] = useState([]);
  const [caseDataLength, setCaseDataLength] = useState(null);
  const [invalidHeaders, setInvalidHeaders] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    var importActiveModule = '';
    var sheetEndRange = null;
    if (typeof window !== `undefined`) {
      importActiveModule = localStorage.getItem('importModule');
    }

    if (importActiveModule === 'cases') {
      sheetEndRange = 8;
    } else if (importActiveModule === 'transaction') {
      sheetEndRange = 0;
    } else if (importActiveModule === 'addresses') {
      sheetEndRange = 0;
    }

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      setRejectedFile(false);

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = () => {
        const binaryStr = reader.result;

        var data = new Uint8Array(binaryStr);
        var arr = [];
        for (var i = 0; i !== data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join('');

        /* Call XLSX */
        var workbook = XLSX.read(bstr, { type: 'binary', cellDates: true });

        /* DO SOMETHING WITH workbook HERE */
        var first_sheet_name = workbook.SheetNames[0];
        /* Get worksheet */
        var worksheet = workbook.Sheets[first_sheet_name];

        try {
          var range = XLSX.utils.decode_range(
            workbook.Sheets[first_sheet_name]['!ref']
          );
          range.s.c = 0; // 0 == XLSX.utils.decode_col("A")
          range.e.c = sheetEndRange; // 6 == XLSX.utils.decode_col("G")
          // range.s.c =
          var new_range = XLSX.utils.encode_range(range);
        } catch (err) {
          setCaseDataLength(0);
        }

        var excelData = XLSX.utils.sheet_to_json(worksheet, {
          raw: true,
          defval: 'NULL',
          dateNF: 'yyyy-mm-dd',
          range: new_range,
        });

        if (excelData.length > 0) {
          var headers = Object.keys(excelData[0]);
          var headerResult = checkHeaders(headers, importActiveModule);
          var cleanObject = {}
          setCaseDataLength(excelData.length);
          if (typeof headerResult === 'boolean') {
            if (importActiveModule === 'cases') {
              cleanObject = processExcelCaseKeys(excelData);
            } else if (importActiveModule === 'transaction') {
              cleanObject = processExcelTransactionKeys(excelData);
            } else if (importActiveModule === 'addresses') {
              cleanObject = processExcelAddressKeys(excelData);
            }

            console.log('cleaned', cleanObject);

            setInvalidHeaders([]);

            // CHECK DATE FORMAT
            cleanObject.forEach((item) => {
              if (!isNaN(new Date(item.Date_Birth).getTime())) {
                moment(item.Date_Birth).format(Dates_Calendar.dateFormat) !==
                'Invalid date'
                  ? (item.Date_Birth = moment(item.Date_Birth).format(
                      Dates_Calendar.dateFormat
                    ))
                  : (item.Date_Birth = item.Date_Birth);
              } else {
                return false;
              }
            });

            setCaseData(cleanObject);
          } else {
            setInvalidHeaders(headerResult);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const onDropRejected = useCallback((rejectedFiles) => {
    console.log(rejectedFiles);
    setRejectedFile(true);
    setCaseDataLength(0);
  }, []);

  const submitFile = () => { 
    updateIsLoadingState(true);

    var importActiveModule = '';
    if (typeof window !== `undefined`) {
      importActiveModule = localStorage.getItem('importModule');
    }

    var endpoint = '';

    if (importActiveModule === 'cases') {
      endpoint = CASES_ENDPOINTS.batchUpload.case;
    } else if (importActiveModule === 'transaction') {
      endpoint = CASES_ENDPOINTS.batchUpload.transaction;
    } else if (importActiveModule === 'addresses') {
      endpoint = CASES_ENDPOINTS.batchUpload.address;
    }

    axios
      .post(endpoint, caseData, auth.getAuthConfig())
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpBadRequest) {
          var wb = XLSX.utils.book_new();
          wb.Props = {
            Title: 'Ospree',
            Subject: 'Ospree',
            Author: 'Ospree',
            CreatedDate: moment(),
          };

          wb.SheetNames.push('Errors');
          var ws_data = err.response.data.content;
          var ws = XLSX.utils.json_to_sheet(ws_data);
          var title =
            acceptedFiles[0].path.split('.')[0] +
            '_' +
            moment().valueOf() +
            '_report' +
            '.csv';
          wb.Sheets['Errors'] = ws;
          var wbout = XLSX.write(wb, { bookType: 'csv', type: 'binary' });
          if (typeof window !== `undefined`) {
            var newReport = wbout;
            var saved = JSON.parse(localStorage.getItem('reportStrings'));

            var newTitle = title;
            var titleSaved = JSON.parse(localStorage.getItem('reportTitles'));

            if (titleSaved.length >= 6) {
              saved.splice(1, 1);
              titleSaved.splice(1, 1);
            }

            saved[saved.length] = newReport;
            newTitle = newTitle.split(' ').join('_');
            titleSaved[titleSaved.length] = newTitle;

            localStorage.setItem('reportStrings', JSON.stringify(saved));
            localStorage.setItem('reportTitles', JSON.stringify(titleSaved));
          }

          updateIsLoadingState(false);
          updateGlobalNotifToggler(true);
        } else if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      })
      .finally(() => {
        if (importActiveModule === 'cases') {
          updateUploadModal();
          updateNewCase(!newCase)
        } else if (importActiveModule === 'transaction') {
          toggleDropZone();
        } else if (importActiveModule === 'addresses') {
          toggleDropZone();
        }
      });
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDropRejected,
    accept: '.xlsx, .xlsm, .csv, application/vnd.ms-excel, text/csv,',
  });

  const files = acceptedFiles.map((file) => (
    <li className="file" key={file.path}>
      <p>
        {' '}
        {file.path} - {file.size} bytes{' '}
      </p>
    </li>
  ));

  const checkHeaders = (header, importActiveModule) => {
    var correctHeaders = []
    if (importActiveModule === 'cases') {
      correctHeaders = [
        'Unique Id',
        'Entity Type',
        'First Name',
        'Last Name',
        'Business Name',
        'Date of Birth',
        'Country Location',
        'Nationality',
        'Registered Country',
      ];
    } else if (importActiveModule === 'addresses') {
      correctHeaders = ['Address'];
    } else if (importActiveModule === 'transaction') {
      correctHeaders = ['Transaction'];
    }

    var counter = 0;
    var invalid = [];

    header.forEach((item) => {
      !correctHeaders.includes(item) ? invalid.push(item) : counter++;
    });

    if (counter === correctHeaders.length) {
      return true;
    }

    return invalid;
  };

  const processExcelCaseKeys = (excelData) => {
    excelData.forEach((data, index) => {
      excelData[index].Business_ID = auth.get('biz_id');
      
      excelData[index] = renameKey(
        data, 
        'Unique Id', 
        'Customer_ID'
      );
      excelData[index] = renameKey(
        excelData[index],
        'Entity Type',
        'Case_Type'
      );
      excelData[index] = renameKey(
        excelData[index],
        'First Name',
        'First_Name'
      );
      excelData[index] = renameKey(
        excelData[index], 
        'Last Name', 
        'Last_Name'
      );
      excelData[index] = renameKey(
        excelData[index],
        'Business Name',
        'Company_Name'
      );
      excelData[index] = renameKey(
        excelData[index],
        'Date of Birth',
        'Date_Birth'
      );
      excelData[index] = renameKey(
        excelData[index],
        'Country Location',
        'Country_Address'
      );
      excelData[index] = renameKey(
        excelData[index],
        'Nationality',
        'Nationality'
      );
      excelData[index] = renameKey(
        excelData[index],
        'Registered Country',
        'Country_Incorporation'
      );
    });

    return excelData;
  };

  const processExcelAddressKeys = (excelData) => {
    excelData.forEach((data, index) => {
      if (typeof window !== `undefined`) {
        excelData[index].case_id = localStorage.getItem('active-case');
        excelData[index].user_id = auth.get('user_id');
        excelData[index] = renameKey(data, 'Address', 'address');
      }
    });

    return excelData;
  };

  const processExcelTransactionKeys = (excelData) => {
    excelData.forEach((data, index) => {
      if (typeof window !== `undefined`) {
        excelData[index].case_id = localStorage.getItem('active-case');
        excelData[index].user_id = auth.get('user_id');
        excelData[index] = renameKey(data, 'Transaction', 'transaction');
      }
    });

    return excelData;
  };

  const clone = (obj) => Object.assign({}, obj);

  const renameKey = (object, key, newKey) => {
    const clonedObj = clone(object);
    const targetKey = clonedObj[key];
    delete clonedObj[key];
    clonedObj[newKey] = targetKey;
    return clonedObj;
  };

  const ImportAddress = () => {
    return (
      parentImportModule === 'Whitelisting' && (
        <button className="btn-outline-blue opaque" onClick={toggleDropZone}>
          Import
        </button>
      )
    );
  };

  const ImportTransaction = () => {
    return (
      parentImportModule === 'Monitoring' && (
        <button className="btn-outline-blue opaque" onClick={toggleDropZone}>
          Import
        </button>
      )
    );
  };

  const saveExportableFile = () => {
    var importActiveModule = '';
    var sheetEndRange = null;
    if (typeof window !== `undefined`) {
      importActiveModule = localStorage.getItem('importModule');
    }

    if (importActiveModule === 'addresses') {
      exportSampleFile(exportableFile[0]);
    } else if (importActiveModule === 'cases') {
      exportSampleFile(exportableFile[1]);
    } else if (importActiveModule === 'transaction') {
      exportSampleFile(exportableFile[2]);
    }
  };

  const exportSampleFile = (config) => {
    var wb = XLSX.utils.book_new();
    wb.Props = {
      Title: 'Ospree',
      Subject: 'Ospree',
      Author: 'Ospree',
      CreatedDate: moment(),
    };

    wb.SheetNames.push('Sheet1');

    var ws = XLSX.utils.json_to_sheet(config.obj, {
      header: config.header,
    });

    var title = config.filename;
    wb.Sheets['Sheet1'] = ws;
    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    saveAs(
      new Blob([s2ab(wbout)], {
        type: 'application/octet-stream',
      }),
      title
    );
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  return (
    <>
      {parentImportModule === 'Cases' && content !== undefined && (
        <div
          onClick={content.containerClass === 'dashed' ? toggleDropZone : null}
          className={classnames('kyc-type', content.containerClass)}
        >
          {content.img && (
            <img src={content.img} className="kyc-image" alt="ospree-kyc" />
          )}
          {content.text && <p> {content.text} </p>}
        </div>
      )}
      <ImportAddress />
      <ImportTransaction />
      <div
        className={classnames('overlay', {
          loading: isLoading,
        })}
      ></div>
      <Modal
        isOpen={dropzoneModal}
        backdrop={false}
        toggle={toggleDropZone}
        modalClassName="uploadModalWrapper"
        backdropClassName="x"
        contentClassName="uploadModalContent"
        className={classnames('uploadModalWindow', 'uploadModal')}
      >
        <ModalBody>
          <div className="upload-type-wrapper">
            <div className="upload-header">
              <p>Upload your file</p>
            </div>
            <div className="upload-option-wrapper dropzone-spot">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <img
                  src={uploadIcon}
                  className="upload-icon"
                  alt="ospree-upload"
                />
                <p className="upload-title">Drag and drop or click here</p>
                <p className="upload-subtitle">
                  All .xlsx, .xlsm and .csv file types are supported.
                </p>
              </div>
              <p className="file error">
                {rejectedFile && 'File format not supported.'}
              </p>
              {files}
            </div>
            <div className="notes-wrapper">
              <div className="note-item">
                <p className="note-title">Download a sample spreadsheet</p>
                <p className="note-description">
                  Need to see this in action first? Download this small
                  <span className="note-download" onClick={saveExportableFile}>
                    {' '}
                    excel sample file{' '}
                  </span>
                  and test the import process so there are no surprises.
                </p>
              </div>

              <div className="note-item">
                <p className="note-title">Have questions?</p>
                <p className="note-description">
                  Migrating data should be easy. Read the{' '}
                  <a
                    href="https://www.ospree.io/ospree/help-desk"
                    target="_blank"
                    className="note-download"
                  >
                    {' '}
                    answer{' '}
                  </a>
                  to all your questions about data security, file, type and
                  troubleshooting.
                </p>
              </div>
            </div>
            <div className="btn-holder">
              <button
                onClick={() => {
                  closeDropZone();
                }}
                className="btn-upload"
              >
                Back
              </button>
              <button
                onClick={submitFile}
                className={classnames('btn-upload', {
                  disabled:
                    rejectedFile ||
                    caseDataLength > 250 ||
                    caseDataLength < 1 ||
                    invalidHeaders.length > 0 ||
                    acceptedFiles.length === 0,
                })}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="error-area">
            <div className="error-list">
              {caseDataLength === 0 && <p>File with no records.</p>}
              {caseDataLength > 250 && (
                <p>'Maximum length of rows reached. (max 250)' </p>
              )}
              {invalidHeaders.length > 0 && (
                <p>
                  {' '}
                  'File format not supported. Verify the column names and try
                  again.'{' '}
                </p>
              )}
              {/* <ul className="invalid-headers">
                {
                  invalidHeaders.length > 0 ?
                    invalidHeaders.map((item, i) => {
                      return (
                        <li>  {item} + ' is an invalid column. </li>
                      )
                    })
                  :
                  null
                }
              </ul> */}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter(UploadOption);
