export const Colour_Schemes = {
  doughnutColourHigh: 'rgba(245,164,163,1)',
  doughnutColourMedium: 'rgba(255,218,129,1)',
  doughnutColourLow: 'rgba(155,219,193,1)',
  addressScreeningColour: 'rgba(203,203,203,1)',
  barGridLinesColour: '#CED4DA',
  transactionActivitiesLineColour: '#606167',
  whitelistingActivitiesLineColour: '#606167',
  transactionActivitiesBGColour: '#ffffff',
  whitelistingActivitiesBGColour: '#ffffff',
};
