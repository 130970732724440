export const BASE_URL = 'https://apitest2.nodestash.com/';
//export const BASE_URL = 'http://127.0.0.1:4194/'


export const LOGIN_ENDPOINT = {
  login: BASE_URL + 'api/login',
};

export const ACCOUNTS_ENDPOINT = {
  resetPassword: BASE_URL + 'api/ui/users/setpassword',
};

export const WHITELISTING_ENDPOINTS = {
  pagedTable: BASE_URL + 'api/whitelist/paged-table?',
  riskBucket: BASE_URL + 'api/whitelist/risk-bucket?',
  addressHist: BASE_URL + 'api/whitelist/address-hist?',
  countAddress: BASE_URL + 'api/whitelist/count-address?',
  assign: BASE_URL + 'api/whitelist/assign-address',
  search: BASE_URL + 'api/whitelist/search-address?',
  remove: BASE_URL + 'api/whitelist/remove-address',
  report: BASE_URL + 'api/reporting/address',
};

export const MONITORING_ENDPOINTS = {
  pagedTable: BASE_URL + 'api/monitoring/paged-table?',
  riskBucket: BASE_URL + 'api/monitoring/risk-bucket?',
  addressHist: BASE_URL + 'api/monitoring/transaction-hist?',
  countTransaction: BASE_URL + 'api/monitoring/count-transaction?',
  assign: BASE_URL + 'api/monitoring/assign-transaction',
  search: BASE_URL + 'api/monitoring/search-transaction?',
  remove: BASE_URL + 'api/monitoring/remove-transaction',
  report: BASE_URL + 'api/reporting/transaction',
  txInvestigation: BASE_URL + 'api/investigation/investigation'
};

export const CASES_ENDPOINTS = {
  dashboard: {
    totalCases: BASE_URL + 'api/case/dashbaord-case-total?',
    caseAddresses: BASE_URL + 'api/case/dashbaord-case-addresses?',
    caseTransactions: BASE_URL + 'api/case/dashbaord-case-transactions?',
  },

  create: {
    individual: BASE_URL + 'api/case/create-case',
    entity: BASE_URL + 'api/case/create-case',
  },

  read: {
    all: BASE_URL + 'api/case/read-all-case?',
    caseActivities: BASE_URL + 'api/case/read-case-activities?',
    caseCustomer: BASE_URL + 'api/case/read-case-customer?',
    addressCase: BASE_URL + 'api/case/read-address-case?',
    detailCase: BASE_URL + 'api/case/read-detail-case?',
    caseTransaction: BASE_URL + 'api/case/read-transaction-case?'    
  },

  batchUpload: {
    case: BASE_URL + 'api/case/batch-create-cases',
    address: BASE_URL + 'api/case/batch-address',
    transaction: BASE_URL + 'api/case/batch-transaction',
  },

  search: BASE_URL + 'api/case/read-case-customer?',
  update: BASE_URL + 'api/case/update-case',
  countries: BASE_URL + 'api/case/get-all-countries',
};

export const PROFILE_ENDPOINTS = {
  readProfile: BASE_URL + 'api/setting/read-userprofile?',
  updateProfile: BASE_URL + 'api/setting/update-userprofile',
};
