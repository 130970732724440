import React, { Fragment, useContext } from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { Context } from '../contexts/Context';

import { Dates_Calendar } from '../helpers/datesCalendar';

import moment from 'moment';

const CustomDateRangePicker = () => {
    // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const { dateRange, updateDateRange, updateIsLoadingState } = useContext(
    Context
  );

  // GET DATERANGE PICKER VALUES
  const handleDateRangeEvent = (e, picker) => {
    updateDateRange(
      picker.startDate.format(Dates_Calendar.dateFormat),
      picker.endDate.format(Dates_Calendar.dateFormat)
    );
    updateIsLoadingState(true);
  };

  return (
    <Fragment>
      <DateRangePicker
        onApply={handleDateRangeEvent}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        locale={{ format: Dates_Calendar.dateFormat }}
        maxDate={moment(moment(), Dates_Calendar.dateFormat).add('days', 1)}
        opens="center"
      >
        <div className="date-holder">
          <p>
            <span>From: </span>
            <span>{dateRange.startDate}</span>
          </p>
          <p>
            <span>To: </span>
            <span>{dateRange.endDate}</span>
          </p>
        </div>
      </DateRangePicker>
    </Fragment>
  );
};

export default CustomDateRangePicker;
