export const Dates_Calendar = {
  dateFormat: 'YYYY-MM-DD',
  dateRange: 30,
};

export const LineChartDateFormat = {
  format: 'YYYY MMMM DD',
  dateRange: 30
}

export const HistogramTickFormat = {
  format: 'MMM D'
}