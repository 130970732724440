import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import {Chart} from "react-google-charts";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap';

import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import { Tooltip } from '@material-ui/core';

import Copy from '../assets/images/copy.svg';

import { Dates_Calendar } from '../helpers/datesCalendar';
import AccordionBody from './AccordionBody';
import moment from 'moment';
import { ReactDatez } from 'react-datez';
import 'react-datez/dist/css/react-datez.css';
import classnames from 'classnames';
import axios from 'axios';
import chroma from 'chroma-js';
import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import response from '../helpers/responseChecker';
import {
  CASES_ENDPOINTS,
  WHITELISTING_ENDPOINTS,
  MONITORING_ENDPOINTS,
} from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';
import { numberWithCommas } from '../helpers/valueFormat';
import copyToClipboard from '../helpers/copyToClipboard';

const SingleAccordionTable = ({ content, index }, props) => {
  const {
    classNameAssign,
    classNameIndividualModal,
    classNameEntityModal,
    classNameRemoveModal,
    classUpgradeModal,
  } = props;

  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    removing,
    assigning,
    activeModule,
    dataLoaded,
    updateRemoving,
    updateAssigning,
    updateIsLoadingState,
  } = useContext(Context);

  const history = useHistory();
  const date = content.CreateTimestamp.split(' ')[0];
  const [publishedID, setPublishedID] = useState({
    ID: '',
    touched: 0,
  });
  const [assignModal, setAssignModal] = useState(false);
  const [modalIndividualModal, setIndividualModal] = useState(false);
  const [modalEntityModal, setEntityModal] = useState(false);
  const [modalRemoveModal, setRemoveModal] = useState(false);
  const [modalUpgradeModal, setUpgradeModal] = useState(false);
  const [showTxInvestigateModal, setTxInvestigateModal] = useState(false);
  const [countries, setCountries] = useState([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [entityErrorMessage, setEntityErrorMessage] = useState('');
  const [individualErrorMessage, setIndividualErrorMessage] = useState('');
  const [assignData, setAssignData] = useState({
    caseID: '',
    customerID: '',
    caseType: '',
    status: '',
    since: '',
  });
  const [accordionItemExpanded, setAccordionItemExpanded] = useState(false);
  const [hashCopyTooltipText, setHashCopyTooltipText] = useState('Click to Copy');

  //graphs
  const [txDetails, setTxDetails] = useState([]);
   

  const [individualCaseData, setIndividualCaseData] = useState({
    customer_id: '',
    first_name: '',
    last_name: '',
    date_birth: '',
    nationality: '',
    country_address: '',
    case_type: 1,
    biz_id: '',
  });

  const [entityCaseData, setEntityCaseData] = useState({
    customer_id: '',
    company_name: '',
    country_incorporation: '',
    country_address: '',
    case_type: 2,
    biz_id: '',
  });

  const endpoint =
    activeModule === 'Whitelisting'
      ? WHITELISTING_ENDPOINTS.report
      : MONITORING_ENDPOINTS.report;

  //check for duplicates
  const inArray = (arr, hash1, hash2) =>{
    
    for (let item of arr){
      //console.log(item);
      if ((item[0] == hash1 || item[0] == hash2) && (item[1]== hash1 || item[1]== hash2)){
        //console.log('true');
        return true;
        //break;       
      }    

    }
    return false;
  }

  const generateReport = (id) => {
    updateIsLoadingState(true);
  if (activeModule === 'Whitelisting') {
    console.log(id);
    axios
      .get(endpoint + '?address_id=' + id, auth.getAuthConfig())
      .then(({ data }) => { 
        window.open(endpoint + '?address_id=' + id, '_blank');
      })
      .catch((error) => {
        console.log(error);
      });
   } else {
    console.log(id);
    axios
      .get(endpoint + '?transaction_id=' + id, auth.getAuthConfig())
      .then(({ data }) => { 
        window.open(endpoint + '?transaction_id=' + id, '_blank');
      })
      .catch((error) => {
        console.log(error);
      });
   }
  };

  const investigate = (id) => {
    updateIsLoadingState(true);
  // if (activeModule === 'Whitelisting') {
  //   setUpgradeModal(!modalUpgradeModal)
    // console.log(id);
    // axios
    //   .get(endpoint + '?address_id=' + id, auth.getAuthConfig())
    //   .then(({ data }) => { 
    //     window.open(endpoint + '?address_id=' + id, '_blank');
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  //  } else {       
   
    history.push({
      pathname:'/tx-details/' + id,
      details: {
        type: activeModule,
        data: content
      }
    });
    //console.log(process.env.PUBLIC_URL);
    // axios
    //   .get(invDetailsUrl + 'transaction=' + id, auth.getAuthConfig())
    //   .then(({ data }) => { 
    //       if (data.content){
    //         //console.log(data);
    //         let d = [];
    //         //let dx= [];
           
            
    //         for (let item of data.content){
    //           //console.log(inArray(d, item.Tx_Hash,item.Address));
    //           if (!inArray(d, item.Tx_Hash,item.Address)){
    //             if (item.Direction == 'withdrawal'){
    //               d.push([item.Address, item.Tx_Hash, item.Address_Risk_Score]);
    //             }
    //             else{
    //               d.push([item.Tx_Hash,item.Address, item.Address_Risk_Score]);
    //             }              
    //           }
    //         }

    //         window.localStorage.setItem('testData', d);
    //         d.unshift(['From', 'To', 'Risk Score']);
    //         //var arr = d.concat(dx);
    //         setTxInvestigateModal(true);            
    //         setTxDetails(d);                            
            
            
    //       }
    //       else{
    //         setTxDetails([]);
    //         setTxInvestigateModal(!showTxInvestigateModal);
    //       }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setTxDetails([]);
    //     setTxInvestigateModal(!showTxInvestigateModal);
    //   });
      
  //  }
  };


  // CLOSE MODALS
  const toggle = (addressID) => {
    setIndividualErrorMessage('');
    setEntityErrorMessage('');
    setErrorMessage('');
    setAssignModal(!assignModal);
    setAssignData({
      caseID: null,
      customerID: null,
      caseType: '',
      status: '',
      since: '',
    });
    setPublishedID({
      ID: null,
      touched: 0,
    });
  };

  const toggleIndividualModal = () => setIndividualModal(!modalIndividualModal);
  const toggleEntityModal = () => setEntityModal(!modalEntityModal);
  const toggleRemoveModal = () => setRemoveModal(!modalRemoveModal);
  const toggleUpgradeModal = () => setUpgradeModal(!modalUpgradeModal);
  const toggleTxInvestigateModal = () => setTxInvestigateModal(!showTxInvestigateModal);

  // const openSankey = () => showInvestigateModel

  const closeModal = (
    <button className="close" onClick={() => toggle(null)}>
      <img
        src={require('../assets/images/x.svg')}
        className="close icon"
        alt="user icon"
      />
    </button>
  );

  const closeConfirmationModal = (
    <button className="close" onClick={toggleRemoveModal}>
      <img
        src={require('../assets/images/x.svg')}
        className="close icon"
        alt="user icon"
      />
    </button>
  );

  const closeUpgradeModal = (
    <button className="close" onClick={toggleUpgradeModal}>
      <img
        src={require('../assets/images/x.svg')}
        className="close icon"
        alt="user icon"
      />
    </button>
  );

  const closeTxInvModal = (
    <button className="close" onClick={toggleTxInvestigateModal}>
      <img
        src={require('../assets/images/x.svg')}
        className="close icon"
        alt="user icon"
      />
    </button>
  );

  const closeIndividualModal = (
    <button className="close" onClick={toggleIndividualModal}>
      <img
        src={require('../assets/images/arrow-left.svg')}
        className="icon"
        alt="arrow left icon"
      />
    </button>
  );

  const closeEntityModal = (
    <button className="close" onClick={toggleEntityModal}>
      <img
        src={require('../assets/images/arrow-left.svg')}
        className="icon"
        alt="arrow left icon"
      />
    </button>
  );

  // SET REMOVING STATE
  const setNewRemovingState = () => {
    removing === true ? setNewRemovingState(false) : updateRemoving(true);
  };

  // ACCESS REMOVE ADDRESS
  const removeAddress = (AddressID) => {
    updateIsLoadingState(true);

    if (activeModule === 'Whitelisting') {
      axios
        .put(
          WHITELISTING_ENDPOINTS.remove,
          { address_id: AddressID },
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          const validator = response.validate(data.code[0], data.message[0]);
          if (validator) {
            setNewRemovingState();
          } else {
            console.log(validator);
          }
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
            history.push('/login');
          }
        });
    } else {
      axios
        .put(
          MONITORING_ENDPOINTS.remove,
          { tx_id: AddressID },
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          const validator = response.validate(data.code[0], data.message[0]);
          if (validator) {
            setNewRemovingState();
          } else {
            console.log(validator);
          }
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
            history.push('/login');
          }
        });
    }
  };

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleIndividualCaseData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'customer_id') {
      if (value === '') {
        setIndividualErrorMessage('Please provide a valid Customer ID.');
      } else {
        setIndividualErrorMessage('');
      }
    }

    setIndividualCaseData({
      ...individualCaseData,
      [name]: value,
    });
  };

  // CREATE INDIVIDUAL CASE
  const submitIndividualCase = () => {
    if (individualCaseData.customer_id === '') {
      setIndividualErrorMessage('Please provide a valid Customer ID.');
    } else {
      updateIsLoadingState(true);
      axios
        .post(
          CASES_ENDPOINTS.create.individual,
          individualCaseData,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          setPublishedID({
            ID: individualCaseData.customer_id,
            touched: 1,
          });

          console.log(individualCaseData);

          toggleIndividualModal();
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
            history.push('/login');
          } else {
            err.response.data.message[0] ===
            'The record already exists or a specified resource is not found'
              ? setIndividualErrorMessage(
                  'A record with this Customer ID already exist.'
                )
              : setIndividualErrorMessage(err.response.data.message[0]);
          }
        });
    }
  };

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleEntityCaseData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'customer_id') {
      if (value === '') {
        setEntityErrorMessage('Please provide a valid Customer ID');
      } else {
        setEntityErrorMessage('');
      }
    }

    setEntityCaseData({
      ...entityCaseData,
      [name]: value,
    });
  };

  // CREATE ENTITY CASE
  const submitEntityCase = () => {
    if (entityCaseData.customer_id === '') {
      setEntityErrorMessage('Please provide a valid Customer ID');
    } else {
      console.log(entityCaseData);
      updateIsLoadingState(true);
      axios
        .post(
          CASES_ENDPOINTS.create.entity,
          entityCaseData,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          setPublishedID({
            ID: entityCaseData.customer_id,
            touched: 1,
          });

          toggleEntityModal();
        })
        .catch((err) => {
          console.log(err.response.data);
          if (err.response.data.code[0] !== RESPONSE_CODES.httpAuthErrCode) {
            err.response.data.message[0] ===
            'The record already exists or a specified resource is not found'
              ? setEntityErrorMessage(
                  'A record with this Customer ID already exist.'
                )
              : setEntityErrorMessage(err.response.data.message[0]);
          } else if (
            err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
          ) {
            history.push('/login');
          }
        });
    }
  };

  // GET ALL COUNTRIES
  const fetchCountries = () => {
    axios
      .get(CASES_ENDPOINTS.countries, auth.getAuthConfig())
      .then(({ data }) => {
        const validator = response.validate(data.code[0], data.message[0]);
        if (validator) {
          setCountries(data.content);
        }
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleSearchCustomerId = (e) => {
    const customerID = e.target.value;
    setPublishedID({
      ID: customerID,
      touched: 1,
    });
  };

  // SEARCH CUSTOMER ID
  const searchCustomerId = () => {
    setErrorMessage('');
    if (publishedID.touched === 1) {
      publishedID.ID !== ''
        ? axios
            .get(
              CASES_ENDPOINTS.search + 'customer_id=' + publishedID.ID,
              auth.getAuthConfig()
            )
            .then(({ data }) => {
              if (typeof window !== `undefined`) {
                localStorage.setItem('new', data.content[0].Case_ID);
              }

              setErrorMessage('');
              setAssignData({
                caseID: data.content[0].Case_ID,
                customerID: data.content[0].Customer_ID,
                caseType: data.content[0].Case_Type,
                status: data.content[0].Case_Status,
                since: data.content[0].Case_Since,
              });
            })
            .catch((err) => {
              if (
                err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
              ) {
                history.push('/login');
              } else {
                setErrorMessage('Please provide a valid Customer ID.');
                setAssignData({
                  caseID: '',
                  customerID: '',
                  caseType: '',
                  status: '',
                  since: '',
                });
              }
            })
        : setErrorMessage('Please provide a valid Customer ID.');
      setAssignData({
        caseID: '',
        customerID: '',
        caseType: '',
        status: '',
        since: '',
      });
    }
  };

  // ASSIGN CASE TO AN ADDRESS
  const assignAddress = (caseID, identifierID) => {
    if (caseID === null) {
      setErrorMessage('Please provide a valid Customer ID.');
    } else {
      updateIsLoadingState(true);
      if (activeModule === 'Whitelisting') {
        axios
          .put(
            WHITELISTING_ENDPOINTS.assign,
            {
              address_id: identifierID,
              case_id: caseID,
            },
            auth.getAuthConfig()
          )
          .then(({ data }) => {
            const validator = response.validate(data.code[0], data.message[0]);
            if (validator) {
              updateAssigning(!assigning);
              toggle(null);
            } else {
              console.log(validator);
            }
          })
          .catch((err) => {
            if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
              history.push('/login');
            }
          });
      } else {
        axios
          .put(
            MONITORING_ENDPOINTS.assign,
            {
              tx_id: identifierID,
              case_id: caseID,
            },
            auth.getAuthConfig()
          )
          .then(({ data }) => {
            const validator = response.validate(data.code[0], data.message[0]);
            if (validator) {
              updateAssigning(!assigning);
              toggle(null);
            } else {
              console.log(validator);
            }
          })
          .catch((err) => {
            if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
              history.push('/login');
            }
          });
      }
    }
  };

  // RETURN COLOR BARS FOR HASHES
  const colorHash = (hash) => {
    const colorScale = chroma.scale(['E4E5E6', 'E4E5E6']).mode('lch').colors(8);
    const start = hash.substring(0, 5);
    const end = hash.slice(-5);
    return (
      <>
        <div className="chroma-hash" id={'UncontrolledTooltipExample-' + index}>
          {start}
          <div
            className="color"
            style={{
              backgroundColor: colorScale[0],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[1],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[2],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[3],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[4],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[5],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[6],
            }}
          ></div>
          <div
            className="color"
            style={{
              backgroundColor: colorScale[7],
            }}
          ></div>
          {end}
        </div>
        <div className="hash-text">
          <strong data-value-type="hash-text">{hash}</strong> 
          <Tooltip title={hashCopyTooltipText}>
            <img 
              src={Copy} 
              className="icon" 
              onClick={copyTextToClipboard}
              data-value-type="hash-text" 
              data-value={hash}
            />
          </Tooltip>
        </div>
        <UncontrolledTooltip
          hideArrow={false}
          placement="right"
          autohide={false}
          target={'UncontrolledTooltipExample-' + index}
        >
          {hash}
        </UncontrolledTooltip>
      </>
    );
  };

  // COPY TEXT TO CLIPBOARD
  const copyTextToClipboard = ({ target }) => {
    copyToClipboard(target.dataset.value)

    setHashCopyTooltipText("Copied!");

    setTimeout(()=>{
      setHashCopyTooltipText("Click to Copy");
    }, 1)
  }

  // TOGGLE ACCORDION ITEM UNLESS HASH TEXT IS CLICKED
  const expandAccordionItem = ({ target }) => {
    if (target.dataset.valueType == "hash-text") return

    if (accordionItemExpanded === false) setAccordionItemExpanded(true)
    else if (accordionItemExpanded === true) setAccordionItemExpanded(false)
  }

  // it can be an address id or a transaction id depending on what the active module is
  const identifierID =
    activeModule === 'Whitelisting'
      ? content.Address_ID
      : content.Transaction_ID;

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIndividualCaseData({
        ...individualCaseData,
        biz_id: localStorage.getItem('biz_id'),
        case_id: '',
        customer_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        company_name: '',
        country_incorporation: '',
        country_address: '',
        date_birth: '',
        nationality: '',
        case_risk: 0,
        sanction: 0,
        adverse_media: 0,
        pep: 0,
        comments: '',
        status: 0,
        date_incoportation: '',
      });

      setEntityCaseData({
        ...entityCaseData,
        biz_id: localStorage.getItem('biz_id'),
        case_id: '',
        customer_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        company_name: '',
        country_incorporation: '',
        country_address: '',
        date_birth: '',
        nationality: '',
        case_risk: 0,
        sanction: 0,
        adverse_media: 0,
        pep: 0,
        comments: '',
        status: 0,
        date_incoportation: '',
      });
    }

    searchCustomerId();
  }, [publishedID, dataLoaded]);

  useEffect(() => {
    fetchCountries();
  }, []);

  return (
    <>
      <AccordionItem 
        dangerouslySetExpanded={accordionItemExpanded} 
        onClick={expandAccordionItem}
      >
        <AccordionItemHeading>  
          <AccordionItemButton>
            <ul className="table-data">

              <li className="table-row">
                {content.Address
                  ? colorHash(content.Address)
                  : colorHash(content.Hash)}
              </li>

              <li className="table-row table-row-hd">{content.Asset}</li>

              <li className="table-row table-row-hd">
                <span className="currency">usd</span>
                {activeModule === 'Whitelisting'
                  ? numberWithCommas(content.Balance_USD)
                  : numberWithCommas(content.Input_USD)}
              </li>

              <li className="table-row table-row-hd">
                <span
                  className={classnames('score-badge', {
                    high:
                      (content.Address_Risk_Score >= 8 &&
                       content.Address_Risk_Score <= 10)  ||
                      (content.Transaction_Risk_Score >= 8 &&
                       content.Transaction_Risk_Score <= 10),
                    medium:
                      (content.Address_Risk_Score >= 6 &&
                       content.Address_Risk_Score <= 7)   ||
                      (content.Transaction_Risk_Score >= 6 &&
                       content.Transaction_Risk_Score <= 7),
                    low:
                      (content.Address_Risk_Score >= -1 &&
                       content.Address_Risk_Score <= 5)   ||
                      (content.Transaction_Risk_Score >= -1 &&
                       content.Transaction_Risk_Score <= 5),
                  })}
                >
                  {activeModule === 'Whitelisting'
                    ? content.Address_Risk_Score
                    : content.Transaction_Risk_Score}
                </span>
              </li>

              <li className="table-row table-row-hd">
                {moment(date).format(Dates_Calendar.dateFormat)}
              </li>

            </ul>
          </AccordionItemButton>
        </AccordionItemHeading>

        <AccordionItemPanel>
          <div className="table-row-dropdown">
            <div className="dropdown-holder action-button">
              <UncontrolledDropdown>
                <DropdownToggle className="btn-blue" caret>
                  Action
                </DropdownToggle>
                <DropdownMenu>

                  <DropdownItem onClick={() => toggle(identifierID)}>
                    <img
                      src={require('../assets/images/user-plus.svg')}
                      className="icon"
                      alt="user plus icon"
                    />
                    Assign
                  </DropdownItem>

                  {(content.Asset === "BTC" || content.Asset === "ETH") && 
                  <DropdownItem  onClick={() => {
                      investigate(
                        activeModule === 'Whitelisting'
                          ? content.Address_ID
                          : content.Hash
                      );
                    }}>
                    <img
                      src={require('../assets/images/crosshair.svg')}
                      className="icon"
                      alt="investigate icon"
                    />
                    Investigate
                  </DropdownItem>}

                  {/* <DropdownItem onClick={toggleUpgradeModal}>
                    <img
                      src={require('../assets/images/icon-eye.svg')}
                      className="icon"
                      alt="investigate icon"
                    />
                    Watch
                  </DropdownItem> */}

                  <DropdownItem
                    onClick={() => {
                      generateReport(
                        activeModule === 'Whitelisting'
                          ? content.Address_ID
                          : content.Transaction_ID
                      );
                    }}
                  >
                    <img
                      src={require('../assets/images/report.svg')}
                      className="icon"
                      alt="investigate icon"
                    />
                    Report
                  </DropdownItem>

                  <DropdownItem onClick={toggleRemoveModal}>
                    <img
                      src={require('../assets/images/trash-2.svg')}
                      className="icon"
                      alt="remove icon"
                    />
                    Remove
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          <AccordionBody
            caseDetails={content}
            // customerProfile={customerProfile}
          />
        </AccordionItemPanel>
      </AccordionItem>

      <Modal isOpen={assignModal} className={classNameAssign}>
        <ModalHeader close={closeModal}>Assign</ModalHeader>
        <ModalBody>
          <Form>
            <h4 className="t-h4">Search Customer ID </h4>
            <FormGroup
              className={classnames('customer-id-input', {
                invalid: errorMessage !== '',
              })}
            >
              <Input
                type="text"
                name="searchCustomerId"
                value={publishedID.ID || ''}
                onChange={handleSearchCustomerId}
                id="search"
                placeholder="Type to search"
              />
              <p className="error-message">{errorMessage}</p>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="type"
                value={assignData.caseType}
                id="type"
                placeholder="Type"
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="status"
                value={assignData.status}
                id="status"
                placeholder="Status"
                disabled
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="case-since"
                value={assignData.since}
                id="case-since"
                placeholder="Case since"
                disabled
              />
            </FormGroup>
          </Form>
        </ModalBody>

        
        <ModalFooter>
          <div className="dropdown-holder create-case">
            <UncontrolledDropdown>
              <DropdownToggle
                className="drop-white btn-custom btn-blue-outline btn-hover-light-violet"
                caret
              >
                <img
                  src={require('../assets/images/plus.svg')}
                  className="icon"
                  alt="plus icon"
                />
                Create Case
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={toggleIndividualModal}>
                  <img
                    src={require('../assets/images/users.svg')}
                    className="icon"
                    alt="users icon"
                  />
                  Individual Case
                </DropdownItem>
                <DropdownItem onClick={toggleEntityModal}>
                  <img
                    src={require('../assets/images/briefcase.svg')}
                    className="icon"
                    alt="briefcase icon"
                  />
                  Entity Case
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>{' '}
          <button
            className="btn-custom btn-blue btn-hover-violet"
            onClick={() => assignAddress(assignData.caseID, identifierID)}
          >
            Assign
          </button>
        </ModalFooter>
      </Modal>



      <Modal
        isOpen={modalIndividualModal}
        toggle={toggleIndividualModal}
        className={classNameIndividualModal}
        backdrop={false}
      >
        <ModalHeader
          toggle={toggleIndividualModal}
          close={closeIndividualModal}
        >
          Create Individual Case
        </ModalHeader>
        <ModalBody>
          <Form>
            <h4 className="t-h4">
              What is the Customer ID of the case you want to create?
            </h4>
            <FormGroup
              className={classnames('customer-id-input', {
                invalid: individualErrorMessage !== '',
              })}
            >
              <Input
                type="text"
                name="customer_id"
                id="id"
                value={individualCaseData.customer_id}
                placeholder="Customer ID e.g. ID0001"
                onChange={handleIndividualCaseData}
              />
              <p className="error-message">{individualErrorMessage}</p>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="first_name"
                id="first-name"
                placeholder="First Name"
                value={individualCaseData.first_name}
                onChange={handleIndividualCaseData}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="last_name"
                id="last-name"
                placeholder="Last Name"
                value={individualCaseData.last_name}
                onChange={handleIndividualCaseData}
              />
            </FormGroup>
            <FormGroup>
              <ReactDatez
                allowPast
                allowFuture={false}
                dateFormat={Dates_Calendar.dateFormat}
                placeholder={`Date of Birth ${Dates_Calendar.dateFormat}`}
                value={individualCaseData.date_birth}
                handleChange={(value) =>
                  setIndividualCaseData({
                    ...individualCaseData,
                    date_birth: moment(value).format(Dates_Calendar.dateFormat),
                  })
                }
                name="dateInput"
              />
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="nationality"
                id="select"
                // value={individualCaseData.nationality}
                onChange={handleIndividualCaseData}
              >
                <option selected disabled hidden>
                  Nationality
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="country_address"
                id="select"
                // value={individualCaseData.country_address}
                onChange={handleIndividualCaseData}
              >
                <option selected disabled hidden>
                  Country Address
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="create-popup-footer">
          <button
            className="btn-custom btn-blue btn-hover-violet"
            onClick={() => submitIndividualCase()}
          >
            Create Case
          </button>
        </ModalFooter>
      </Modal>



      <Modal 
        isOpen={modalEntityModal}
        backdrop={false}
        toggle={toggleEntityModal}
        className={classNameEntityModal}
      >
        <ModalHeader toggle={toggleEntityModal} close={closeEntityModal}>
          Create Entity Case
        </ModalHeader>
        <ModalBody>
          <Form>
            <h4 className="t-h4">
              What is the Customer ID of the case you want to create?
            </h4>
            <FormGroup
              className={classnames('customer-id-input', {
                invalid: entityErrorMessage !== '',
              })}
            >
              <Input
                type="text"
                name="customer_id"
                id="id"
                placeholder="Customer ID e.g. ID0001"
                value={entityCaseData.customer_id}
                onChange={handleEntityCaseData}
              />
              <p className="error-message">{entityErrorMessage}</p>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="company_name"
                id="company-name"
                placeholder="Company Name"
                value={entityCaseData.company_name}
                onChange={handleEntityCaseData}
              />
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="country_incorporation"
                id="select"
                // value={entityCaseData.country_incorporation}
                onChange={handleEntityCaseData}
              >
                <option selected disabled hidden>
                  Incoporation Country
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="country_address"
                id="select"
                // value={entityCaseData.country_address}
                onChange={handleEntityCaseData}
              >
                <option selected disabled hidden>
                  Company Address
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="create-popup-footer">
          <button
            className="btn-custom btn-blue btn-hover-violet"
            onClick={submitEntityCase}
          >
            Create Case
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalRemoveModal}
        toggle={toggleRemoveModal}
        id="remove-popup"
        className={classUpgradeModal}
        // backdrop={false}
      >
        <ModalHeader toggle={toggleRemoveModal} close={closeConfirmationModal}>
          Delete address
        </ModalHeader>
        <ModalBody>
          <p className="description">
            Are you sure you want to delete this address? This cannot be undone.{' '}
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn-custom btn-outline-grey"
            onClick={toggleRemoveModal}
          >
            Cancel
          </button>
          <button
            className="btn-custom btn-red"
            onClick={() => {
              removeAddress(
                activeModule === 'Whitelisting'
                  ? content.Address_ID
                  : content.Transaction_ID
              );
              toggleRemoveModal();
            }}
          >
            Delete
          </button>{' '}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalUpgradeModal}
        toggle={toggleUpgradeModal}
        id="upgrade-popup"
        className={classNameRemoveModal}
        // backdrop={false}
      >
        <ModalHeader toggle={toggleUpgradeModal} close={closeUpgradeModal}>
          Upgrade Plan
        </ModalHeader>
        <ModalBody>
          <p className="description">
            Upgrade to Business or Enterprise Plan to unlock additional
            features.{' '}
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn-custom btn-outline-grey"
            onClick={() => {
              removeAddress(
                activeModule === 'Whitelisting'
                  ? content.Address_ID
                  : content.Transaction_ID
              );
              toggleRemoveModal();
            }}
            onClick={toggleUpgradeModal}
          >
            Maybe Later
          </button>
          <button
            className="btn-custom btn-red"
            onClick={() => {
              window.open('https://ospree.io/get-demo');
              toggleUpgradeModal();
            }}
          >
            ✓ Upgrade
          </button>{' '}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showTxInvestigateModal}
        toggle={toggleTxInvestigateModal}
        size='xl'
        id="sankey-popup"
        className='modal-container custom-map-modal'
        // backdrop={false}
      >
        <ModalHeader toggle={toggleTxInvestigateModal} close={closeTxInvModal}>
          Transaction Details
        </ModalHeader>
        <ModalBody>     
          {txDetails.length > 0 ?
            (<Chart
              width={"100%"}
              height={"500px"}
              chartType="Sankey"
              loader={<div>Loading Chart..</div>}
              options={{
                sankey: {
                  //link: { color: { fill: '#d799ae' } },
                  node: {
                    //colors: ['#C92100', '#F76F6C', '#F76F6C', '#D0ACE4', '#D0ACE4'],
                    nodePadding: 30,
                    labelPadding: 30,
                    label: { fontName:'Roboto', fontSize:13 },
                  },                
                },
              }}
              data={txDetails}
              rootProps={{ 'data-testid': '1' }}
           />):( <p className="description">
           No transaction details found.{' '}
         </p>)}
          
          {/* <Graph graph={graph} options={options} events={events} style={{ height: "640px", width:'100%', left:'50%' }} /> */}
         
        </ModalBody>
        <ModalFooter>
          <button
            className="btn-custom btn-outline-grey"
            onClick={() => {
              removeAddress(
                activeModule === 'Whitelisting'
                  ? content.Address_ID
                  : content.Transaction_ID
              );
              toggleRemoveModal();
            }}
            onClick={toggleTxInvestigateModal}
          >
            Close
          </button>
         {' '}
        </ModalFooter>
      </Modal>

    </>
  );
};

export default withRouter(SingleAccordionTable);
