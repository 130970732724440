import React, { useState } from 'react';
import axios from 'axios';

import auth from '../helpers/auth';
import response from '../helpers/responseChecker';
import { External_URLs } from '../helpers/externalUrls';
import { LOGIN_ENDPOINT } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const AccountActivation = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userCredentials, setUserCredentials] = useState({
    password: '',
    confirmPassword: '',
  });

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserCredentials({
      ...userCredentials,
      [name]: value.replace(/\s/g, ''),
    });
  };

  // SEND STATE VARIABLES' VALUES TO THE API
  const handleSubmit = (e) => {
    e.preventDefault();

  };

  return (
    <>
      <header className="account-activation-header">
        <div className="container account-activation-container">
          <a href={External_URLs.mainSite}>
            <img
              src={require('../assets/images/brand-logo-white.png')}
              className="account-activation-logo"
              alt="account-activation-logo"
            />
          </a>
        </div>
      </header>

      <div className="account-activation">
        <div className="container account-activation-container">
          <div className="account-activation-wrapper">
            <div className="form-wrapper">
              <div className="form-content">
                <h1 className="account-activation-title">Account Activation</h1>
                <p className="account-activation-description">Please change your password below to finish activation</p>
                <form className="form" onSubmit={handleSubmit}>
                  <label htmlFor="password" className="subtitle">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    onChange={handleData}
                  />
                  <label htmlFor="confirm-password" className="subtitle">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirm-password"
                    onChange={handleData}
                  />
                  <button
                    type="submit"
                    className="btn-custom btn-blue btn-hover-violet"
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </button>
                  <p className="error-message">{errorMsg}</p>
                </form>
              </div>
              <a
                href={External_URLs.privacyPolicy}
                className="privacy-terms-link"
              >
                Privacy Policy • Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountActivation;
