import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faUserFriends, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Assign = (props) => {
  const {
    className,
    classNameIndividualModal,
    classNameEntityModal
  } = props;

  const [modal, setModal] = useState(false);
  const [modalIndividualModal, setIndividualModal] = useState(false);
  const [modalEntityModal, setEntityModal] = useState(false);

  const toggle = () => setModal(!modal);
  const toggle2 = () => setIndividualModal(!modalIndividualModal);
  const toggle3 = () => setEntityModal(!modalEntityModal);

  const closeIndividualModal = <button className="close" onClick={toggle2}><FontAwesomeIcon icon={faArrowLeft} className="icon" /></button>;

  const closeEntityModal = <button className="close" onClick={toggle3}><FontAwesomeIcon icon={faArrowLeft} className="icon" /></button>;

  return (
    <div>
      <Button color="danger" onClick={toggle}>Assign</Button>
      <Modal isOpen={modal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Assign</ModalHeader>
        <ModalBody>
        <Form>
          <h4 className="t-h4">Search Customer ID</h4>
          <FormGroup>
            <Input
              type="text"
              name="search"
              id="search"
              placeholder="Type to search"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="type"
              id="type"
              placeholder="Type"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="status"
              id="status"
              placeholder="Status"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="case-since"
              id="case-since"
              placeholder="Case since"
            />
          </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter>
          <div className="dropdown-holder">
            <UncontrolledDropdown direction="up">
              <DropdownToggle caret>
                Create case
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={toggle2}>
                  <FontAwesomeIcon icon={faUserFriends} className="icon" />
                  Individual Case
                </DropdownItem>
                <DropdownItem onClick={toggle3}>
                 <FontAwesomeIcon icon={faBuilding} className="icon" />
                  Entity Case
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          {' '}
          <button className="btn-custom btn-blue">Assign</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalIndividualModal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }}
        toggle={toggle2} className={classNameIndividualModal}>
        <ModalHeader toggle={toggle2} close={closeIndividualModal}>Create Individual Case</ModalHeader>
        <ModalBody>
        <Form>
          <h4 className="t-h4">What is the unique id of the case you want to create?</h4>
          <FormGroup>
            <Input
              type="text"
              name="id"
              id="id"
              placeholder="Unique ID e.g. ID0001"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="first-name"
              id="first-name"
              placeholder="First Name"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="last-name"
              id="last-name"
              placeholder="Last Name"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="dob"
              id="dob"
              placeholder="Date of Birth"
            />
          </FormGroup>

          <FormGroup className="select-holder">
            <Input type="select" name="select" id="select">
              <option selected disabled hidden>Nationality</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </FormGroup>

          <FormGroup className="select-holder">
            <Input type="select" name="select" id="select">
              <option selected disabled hidden>Country Address</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter className="create-popup-footer">
          <button className="btn-custom btn-blue">Create Case</button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalEntityModal} modalTransition={{ timeout: 700 }} backdropTransition={{ timeout: 1300 }} toggle={toggle3} className={classNameEntityModal}>
        <ModalHeader toggle={toggle3} close={closeEntityModal}>Create Entity Case</ModalHeader>
        <ModalBody>
        <Form>
          <h4 className="t-h4">What is the unique id of the case you want to create?</h4>
          <FormGroup>
            <Input
              type="text"
              name="id"
              id="id"
              placeholder="Unique ID e.g. ID0001"
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="company-name"
              id="company-name"
              placeholder="Company Name"
            />
          </FormGroup>

          <FormGroup className="select-holder">
            <Input type="select" name="select" id="select">
              <option selected disabled hidden>Incoporation Country</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </FormGroup>

          <FormGroup className="select-holder">
            <Input type="select" name="select" id="select">
              <option selected disabled hidden>Company Address</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Input>
          </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter className="create-popup-footer">
          <button className="btn-custom btn-blue">Create Case</button>
        </ModalFooter>
        </Modal>

    </div>
  );
}

export default Assign;