import React, { useContext } from 'react';
import classnames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import UploadOption from './UploadOption';
import { Context } from '../contexts/Context';

const UploadWindow = () => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    isLoading,
    uploadModal,
    updateUploadModal,
    updateUploadModalOpen,
  } = useContext(Context);

  // AVAILABLE KYC OPTIONS
  const options = [
    {
      containerClass: 'flat',
      img: require('../assets/images/logo-jumio.png'),
    },
    {
      containerClass: 'flat',
      img: require('../assets/images/logo-onfido.png'),
    },
    {
      containerClass: 'flat',
      img: require('../assets/images/logo-acuris.png'),
    },
    {
      containerClass: 'dashed',
      text: 'My KYC solution is not in this list',
    },
  ];

  return (
    <>
      <button
        className="btn-outline-blue opaque"
        onClick={updateUploadModalOpen}
      >
        Import
      </button>
      <div>
        <div
          className={classnames('overlay', {
            loading: isLoading,
          })}
        ></div>
        <Modal
          isOpen={uploadModal}
          backdrop={false}
          toggle={updateUploadModal}
          wrapClassName="x"
          modalClassName="uploadModalWrapper"
          contentClassName="uploadModalContent"
          className={classnames('uploadModalWindow', 'uploadModal')}
        >
          <ModalBody>
            <div className="upload-type-wrapper">
              <div className="upload-header">
                <p>Choose your Know Your Customer (KYC) solution</p>
              </div>
              <div className="upload-option-wrapper">
                {options.map((option, i) => {
                  return <UploadOption key={i} content={option} />;
                })}
              </div>
              <div className="btn-holder">
                <button onClick={updateUploadModal} className="btn-upload">
                  Back
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default UploadWindow;
