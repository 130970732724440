import React, { useState, useEffect, useContext } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { Form, FormGroup, Label, Input, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import Header from '../components/header'
import classnames from 'classnames'
import axios from 'axios'
import auth from '../helpers/auth'
import response from '../helpers/responseChecker'
import { PROFILE_ENDPOINTS } from "../helpers/urls";
import { RESPONSE_CODES } from "../helpers/httpCodes";
import { Context } from '../contexts/Context'

const UserProfile = props => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    isProfileUpdated,
    updateIsLoadingState,
    updateIsProfileUpdated,
  } = useContext(Context);
  
  const history = useHistory()
  const [userData, setUserData] = useState({})
  const [personalData, setPersonalData] = useState({})
  const userID = auth.get('user_id')
  const [activeTab, setActiveTab] = useState('1');

  // READ USER DATA
  const fetchUserData = () => {
    // updateIsLoadingState(true)
    axios.get(
      PROFILE_ENDPOINTS.readProfile + 'user_id=' + userID,
      auth.getAuthConfig()
    )
    .then(({data}) => {
      const validator = response.validate(data.code[0], data.message[0])
      if (validator) {
        setUserData(data.content[0])
        setPersonalData({
          user_id : data.content[0].User_ID,
          fullname: data.content[0].FullName,
          business_name: data.content[0].Business_Name,
          user_role: data.content[0].User_Role,
          user_timezone: data.content[0].User_TimeZone,
          user_email: data.content[0].User_Name
        })
        updateIsLoadingState(false)
        updateIsProfileUpdated(false)
      } else {
        console.log(validator)
      }
    })
    .catch(err => {
      if(err.response.data.code && err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
        history.push('/login')
      }
    })
  }

  // SAVE USER DATA 
  function saveUserProfile(e) {
    e.preventDefault()
    updateIsLoadingState(true)
    
    if (personalData.fullname === '' || !(/\S/.test(personalData.fullname))) {
      personalData.fullname = userData.FullName
    }

    if(personalData.business_name === '' || !(/\S/.test(personalData.business_name))) {
      personalData.business_name = userData.Business_Name
    }

    axios.put(
      PROFILE_ENDPOINTS.updateProfile, 
      personalData,
      auth.getAuthConfig()
    )
    .then(({data}) => {
      console.log(personalData)
      setPersonalData(personalData)
      if (typeof window !== `undefined`) {
        localStorage.setItem('fullname', personalData.fullname)
      }
      updateIsLoadingState(false)
      updateIsProfileUpdated(true)
    })
    .catch(err => {
      if(err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
        history.push('/login')
      }
    })
  }

  // SET ACTIVE TAB
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handlePersonalInfo = e => {
    const name = e.target.name
    const value = e.target.value

    setPersonalData({
      ...personalData,
      [name]: value
    })
  }

  const handleChangePassword = () => {
    history.push('/reset-password')
  }

  useEffect(() => {
    fetchUserData()
  }, [isProfileUpdated])

  return (
    <>
    <Header />
    <main className="user-profile">
      <div className="container">
        <div className="user-profile-content">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Personal Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Account Information
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => { toggle('3'); }}
              >
                Organization Details
              </NavLink>
            </NavItem>
          </Nav>

          <div className="section-profile">
            <div className="content">
              <div className="profile-header">
                <div className="user-icon-wrapper">
                  <img src={require('../assets/images/user.svg')} className="icon" alt="user-2 icon"/>
                </div>
                <h1>Manage your profile</h1>
              </div>
              <Form onSubmit={saveUserProfile}>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <div className="form-wrapper">
                      <FormGroup>
                        <Label for="full-name">Full Name</Label>
                        <Input
                          type="text"
                          name="fullname"
                          id="full-name"
                          value={personalData.fullname || ''}
                          onChange={handlePersonalInfo}
                          placeholder="e.g John Smith"
                          className = "enable"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="role">Role</Label>
                        <Input
                          type="text"
                          name="role"
                          id="role"
                          defaultValue={userData.User_Role || ''}
                          placeholder="e.g Compliance Officer"

                        />
                    </FormGroup>
                      <FormGroup> 
                        <Label for="timezone">Timezone</Label>
                        <div className="select-holder">
                          <Input 
                          type="select" 
                          name="user_timezone" 
                          id="timezone"  
                          defaultValue={userData.User_TimeZone || ''}
                          >
                            <option>{userData.User_TimeZone}</option>
                          </Input>
                        </div>
                      </FormGroup>
                    </div>    
                  </TabPane>
                  
                  <TabPane tabId="2">
                    <div className="form-wrapper">
                      <FormGroup>
                        <Label for="user-id">User Id</Label>
                        <Input
                          type="text"
                          name="user-id"
                          id="user-id"
                          defaultValue={userData.User_ID || ''}
                          placeholder="xxxxxxxxx"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="email">User Name</Label>
                        <Input
                          type="email"
                          name="username"
                          defaultValue={userData.User_Name || ''}
                          placeholder="e.g john.smith@company.com"

                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Password</Label>
                        <div className="button-wrapper-password">
                          <button className="btn-blue" onClick={handleChangePassword}>
                            Change Password
                          </button>
                        </div>
                      </FormGroup>
                    </div>    
                  </TabPane>

                  <TabPane tabId="3">
                    <div className="form-wrapper">
                      <FormGroup>
                        <Label for="business-name">Business Name</Label>
                        <Input
                          type="text"
                          name="business_name"
                          id="business-name"
                          defaultValue={userData.Business_Name || ''}
                          placeholder="e.g Company Inc."
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="business-activity">Business Activity</Label>
                        <Input
                          type="text"
                          name="business-activity"
                          id="business-activity"
                          defaultValue={userData.Business_Activity || ''}
                          placeholder="e.g Exchange"
                        />
                      </FormGroup>
                      
                      <FormGroup>
                        <Label for="business-plan">Business Plan</Label>
                        <Input
                          type="text"
                          name="business-plan"
                          id="business-plan"
                          defaultValue={userData.Plan || ''}
                          placeholder="e.g Start Up"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="address-line1">Address Line 1</Label>
                        <Input
                          type="text"
                          name="address-line1"
                          id="address-line1"
                          defaultValue={userData.Business_Address_1 || ''}
                          placeholder="e.g Raffles Place, Ocean..."
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="address-line2">Address Line 2</Label>
                        <Input
                          type="text"
                          name="address-line2"
                          id="address-line2"
                          defaultValue={userData.Business_Address_2 || ''}
                          placeholder="--"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="country">Country</Label>
                          <Input type="text"
                            name="country" 
                            id="country" 
                            defaultValue={userData.Business_Country || ''}>
                          </Input>
                      </FormGroup>
                    </div>
                  </TabPane>
                </TabContent>
                {activeTab === '1' && <div className="profile-footer">
                  <div className="button-wrapper">
                    <button type="submit" className="btn-custom btn-blue">Save</button>
                  </div>
                </div>}
              </Form>
            </div>
          </div>      
        </div>
      </div>
    </main>
    </>
  );
}

export default withRouter(UserProfile)
