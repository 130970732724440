class ResponseChecker {
  validate(code, message) {
    if (code === 200) {
      return true
    } else {
      return code + '=>' + message
    }
  }
}


export default new ResponseChecker()