import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Doughnut, Bar } from 'react-chartjs-2';

import Header from '../components/header';
import SearchBar from '../components/SearchBar';
import AccordionTable from '../components/AccordionTable';

import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { WHITELISTING_ENDPOINTS } from '../helpers/urls';
import { Colour_Schemes } from '../helpers/colourSchemes';
import { RESPONSE_CODES } from '../helpers/httpCodes';
import { Dates_Calendar, HistogramTickFormat } from '../helpers/datesCalendar';

const Home = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    dateRange,
    sortBy,
    sortDir,
    removing,
    assigning,
    searchState,
    pageIndex,
    pageSize,
    isLoading,
    updateIsLoadingState,
    updatePageIndex,
    updatePageTotalItems,
    updateTotalPages,
    updateParentImportModule,
    updateSortBy,
    updateSortDir,
    updateDataLoaded,
  } = useContext(Context);

  const history = useHistory();

  const [doughnutData, setDoughnutData] = useState({});
  const [doughnutResults, setDoughnutResults] = useState({});
  const [barData, setBarData] = useState({});
  const [addressCount, setAddressCount] = useState({
    count: [],
  });
  const [whitelistData, setWhitelistData] = useState({
    Module: 'Whitelisting',
    TableRow1Title: 'Address',
    TableRow2Title: 'Asset',
    TableRow3Title: 'Risk Score',
    TableRow4Title: 'Timestamp',
    items: [],
    total: [],
    biz_total: [],
  });

  // api response checker
  const responseChecker = (code, message) => {
    if (code === 200) {
      return true;
    } else {
      return code + '=>' + message;
    }
  };

  // ACCESS WHITELISTING'S PAGED TABLE
  const pagedTable = () => {
    if (sortBy !== 'AddressCount') {
      axios
        .get(
          WHITELISTING_ENDPOINTS.pagedTable +
            'biz_id=' +
            auth.get('biz_id') +
            '&end_range=' +
            dateRange.endDate +
            '&start_range=' +
            dateRange.startDate +
            '&sortby=' +
            sortBy +
            '&sortdir=' +
            sortDir +
            '&pageindex=' +
            (pageIndex - 1) +
            '&pagesize=' +
            pageSize,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          const validator = responseChecker(data.code[0], data.message[0]);
          // console.log(data.content);
          if (validator) {
            setWhitelistData({
              Module: 'Whitelisting',
              TableRow1Title: 'Address',
              TableRow2Title: 'Asset',
              TableRow3Title: 'Balance',
              TableRow4Title: 'Risk Score',
              TableRow5Title: 'Timestamp',
              items: data.content.items,
              total: data.content.total[0],
              biz_total: data.content.biz_total[0],
              illustrationHeading: 'Search your first address',
              illustrationDesc: (
                <>
                  Use the <span>search bar</span> at the top of the page to
                  conduct an address search query
                </>
              ),
            });

            updateDataLoaded();
            updatePageTotalItems(data.content.total[0]);
            updateTotalPages(Math.ceil(data.content.total[0] / pageSize));
          } else {
            console.log(validator);
          }
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpBadRequest) {
            setWhitelistData({
              Module: 'Whitelisting',
              TableRow1Title: 'Address',
              TableRow2Title: 'Asset',
              TableRow3Title: 'Balance',
              TableRow4Title: 'Risk Score',
              TableRow5Title: 'Timestamp',
              items: [],
              total: 0,
              biz_total: 0,
              illustrationHeading: 'Search your first address',
              illustrationDesc: (
                <>
                  Use the <span>search bar</span> at the top of the page to
                  conduct an address search query
                </>
              ),
            });
            updateIsLoadingState(false);
            updateDataLoaded();
            updatePageTotalItems(0);
            updateTotalPages(0);
          } else if (
            err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
          ) {
            history.push('/login');
          }
        });
    }
  };

  // ACCESS WHITELISTING'S RISK BUCKET
  const riskBucket = () => {
    axios
      .get(
        WHITELISTING_ENDPOINTS.riskBucket +
          'biz_id=' +
          auth.get('biz_id') +
          '&start_range=' +
          dateRange.startDate +
          '&end_range=' +
          dateRange.endDate,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        const validator = responseChecker(data.code[0], data.message[0]);

        if (validator) {
          const highResult = data.content[2].Total;
          const mediumResult = data.content[1].Total;
          const lowResult = data.content[0].Total;

          setDoughnutResults({
            highResult: highResult,
            mediumResult: mediumResult,
            lowResult: lowResult,
          });

          setDoughnutData({
            labels: ['High', 'Medium', 'Low'],
            datasets: [
              {
                data: [
                  data.content[2].Total,
                  data.content[1].Total,
                  data.content[0].Total,
                ],
                backgroundColor: [
                  Colour_Schemes.doughnutColourHigh,
                  Colour_Schemes.doughnutColourMedium,
                  Colour_Schemes.doughnutColourLow,
                ],
                borderWidth: 2,
              },
            ],
          });
        } else {
          console.log(validator);
        }
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // ACCESS WHITELISTING'S ADDRESS HIST
  const addressHist = () => {
    axios
      .get(
        WHITELISTING_ENDPOINTS.addressHist +
          'biz_id=' +
          auth.get('biz_id') +
          '&end_range=' +
          dateRange.endDate +
          '&start_range=' +
          dateRange.startDate,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        const validator = responseChecker(data.code[0], data.message[0]);
        if (validator) {
          // get dates between start range and end range
          let chartValues = getDates(data.content);

          // set chart options
          let labels = chartValues.map((o) => o.label);
          let values = chartValues.map((o) => o.count);

          setBarData({
            labels: labels,
            datasets: [
              {
                label: 'Daily Activity',
                data: values,
                backgroundColor: Colour_Schemes.addressScreeningColour,
              },
            ],
          });

          updateIsLoadingState(false);
        } else {
          console.log(validator);
        }
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpBadRequest) {
          let chartValues = getDates([]);

          // set chart options
          let labels = chartValues.map((o) => o.label);
          let values = chartValues.map((o) => o.count);

          setBarData({
            labels: labels,
            datasets: [
              {
                label: 'Daily Activity',
                data: values,
                backgroundColor: Colour_Schemes.addressScreeningColour,
              },
            ],
          });
          updateIsLoadingState(false);
        } else if (
          err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
        ) {
          history.push('/login');
        }
      });
  };

  // ACCESS WHITELISTING'S COUNT ADDRESS
  const countAddress = () => {
    axios
      .get(
        WHITELISTING_ENDPOINTS.countAddress +
          'end_range=' +
          dateRange.endDate +
          '&start_range=' +
          dateRange.startDate +
          '&biz_id=' +
          auth.get('biz_id'),
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        const validator = responseChecker(data.code[0], data.message[0]);
        // console.log('count object', data.content[0]);
        validator
          ? setAddressCount({
              count: data.content[0].unique_add_count,
            })
          : console.log(validator);
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // GET DATES THAT ARE AVAILABLE IN BETWEEN TWO DATES
  const getDates = (data) => {
    var days = [];
    var currentDate = moment(dateRange.startDate);
    var stopDate = moment(dateRange.endDate);

    while (currentDate <= stopDate) {
      days.push({
        label: moment(currentDate).format(Dates_Calendar.dateFormat),
      });
      currentDate = moment(currentDate).add(1, 'days');
    }

    let copyArr = days;

    data.forEach((result) => {
      copyArr.forEach((item, i) => {
        if (result.CreateTimestamp === item.label) {
          days[i].count = result.count;
        }
      });
    });

    days.forEach((item, i) => {
      if (item.count === undefined) {
        days[i].count = 0;
      }
    });

    return days;
  };

  useEffect(() => {
    pagedTable();
    countAddress();
    riskBucket();
    addressHist();
  }, [
    dateRange,
    searchState,
    sortBy,
    sortDir,
    removing,
    assigning,
    isLoading,
    pageIndex,
    pageSize,
  ]);

  useEffect(() => {
    updateSortBy('CreateTimestamp');
    updateSortDir('DESC');
    updatePageIndex(1);
    updateParentImportModule('');
  }, []);

  return (
    <div className="home">
      <Header />
      <SearchBar />
      <div className="content">
        <div className="container">
          <div className="chart-row-wrapper">
            <div className="chart-wrapper doughnut">
              <div className="title-wrapper">
                <p className="chart-title title mb-0">
                  Address Distribution by Risk Score
                </p>
                <img
                  className="icon"
                  src={require('../assets/images/icon-2.svg')}
                  alt="ospree-icon"
                />
              </div>
              <div className="doughnut-holder">
                <div className="doughnut-wrapper">
                  <div className="doughnut-content">
                    <p className="total">{addressCount.count}</p>
                    <p className="doughnut-content-text">TOTAL ADDRESSES</p>
                  </div>

                  {/* SUPPLY PIE CHART PARAMETERS */}
                  <Doughnut
                    data={doughnutData}
                    width={200}
                    height={200}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </div>
                <div className="doughnut-wrapper">
                  <div className="doughnut-col">
                    <p className="label">Score Ranges</p>
                    <ul className="doughnut-score-range">
                      <li>8-10</li>
                      <li>6-7</li>
                      <li>0-5</li>
                    </ul>
                  </div>
                  <div className="doughnut-col">
                    <p className="label">Results</p>
                    <ul className="doughnut-results">
                      <li>{doughnutResults.highResult}</li>
                      <li>{doughnutResults.mediumResult}</li>
                      <li>{doughnutResults.lowResult}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="chart-wrapper bar">
              <div className="title-wrapper">
                <p className="chart-title title mb-0">
                  Daily Whitelisting Activity
                </p>
                <img
                  className="icon"
                  src={require('../assets/images/icon-1.svg')}
                  alt="ospree-icon"
                />
              </div>
              <div className="bar-wrapper">
                {/* SUPPLY BAR CHART PARAMETER */}
                <Bar
                  data={barData}
                  options={{
                    responsive: true,
                    legend: {
                      position: 'top',
                      align: 'end',
                    },
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            drawBorder: false,
                            display: false,
                          },
                          type: 'time',
                          position: 'bottom',
                          time: {
                            displayFormats: {
                              week: HistogramTickFormat.format,
                            },
                            tooltipFormat: Dates_Calendar.dateFormat,
                            unit: 'week',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: Colour_Schemes.barGridLinesColour,
                            drawBorder: false,
                          },

                          ticks: {
                            min: 0,
                            suggestedMax: 100,
                            padding: 5,
                            stepSize: 25,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </div>
          </div>
          <AccordionTable content={whitelistData} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
