import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';

//Components
import Header from '../components/header';
import BreakdownBlock from '../components/BreakdownBlock';
import Table from '../components/Table';

//helpers
import auth from '../helpers/auth';
import { Context } from '../contexts/Context';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const CaseManagement = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    newCase,
    notifToggle,
    filterCaseType,
    filterCaseStatus,
    updateActiveModule,
    updateParentImportModule,
    updateSortDir,
    updateSortBy,
  } = useContext(Context);

  const history = useHistory();

  const [totalCase, setTotalCases] = useState({});
  const [totalAddress, setTotalAddress] = useState({});
  const [totalTransaction, setTotalTransactions] = useState({});

  // ACCESS DASHBOARD'S TOTAL CASES
  const fetchTotalCases = () => {
    var endpoint = '';
    endpoint = CASES_ENDPOINTS.dashboard.totalCases + 'biz_id=' + auth.get('biz_id');
      
    if (filterCaseStatus !== null && filterCaseType === null) {
      endpoint += '&status_id=' + filterCaseStatus;
    } else if (filterCaseStatus === null && filterCaseType !== null) {
      endpoint += '&case_type_id=' + filterCaseType;
    } else if (filterCaseStatus !== null && filterCaseType !== null) {
      endpoint += '&status_id=' + filterCaseStatus + '&case_type_id=' + filterCaseType;
    }

    axios
      .get(endpoint, auth.getAuthConfig())
      .then(({ data }) => {
        var totalObj = {
          title: 'Total Cases',
          figureTitle: 'All-Time',
          figureSubTitle: 'Activity',
          figureValue: data.content[0].TotalCases,
          isEntity: true,
          entityValue: data.content[0].Entities,
          isIndividual: true,
          individualValue: data.content[0].Individuals,
        };
        setTotalCases(totalObj);
        return totalObj;
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };
  
  // ACCESS DASHBOARD'S TOTAL ADDRESSES
  const fetchAddresses = () => {
    var endpoint = CASES_ENDPOINTS.dashboard.caseAddresses + 'biz_id=' + auth.get('biz_id');
    
    if (filterCaseStatus !== null && filterCaseType === null) {
      endpoint += '&status_id=' + filterCaseStatus;
    } else if (filterCaseStatus === null && filterCaseType !== null) {
      endpoint += '&case_type_id=' + filterCaseType;
    } else if (filterCaseStatus !== null && filterCaseType !== null) {
      endpoint +=
        '&status_id=' + filterCaseStatus + '&case_type_id=' + filterCaseType;
    }

    axios
      .get(endpoint, auth.getAuthConfig())
      .then(({ data }) => {
        var addresObj = {
          title: 'Address Whitelisting Activity',
          figureTitle: 'All-Time',
          figureSubTitle: 'Activity',
          figureValue: data.content[0].TotalAddress,
          isBtc: true,
          btcValue: data.content[0].BTC,
          //btcPercentage: '(62%)',
          isEth: true,
          ethValue: data.content[0].ETH,
          //ethPercentage: '(22%)',
          isErc: true,
          ercValue: data.content[0]['ERC-20'],
          //ercPercentage: '(12%)',
          isLtc: true,
          ltcValue: data.content[0].LTC,
          //ltcPercentage: '(2%)',
          isBch: true,
          bchValue: data.content[0].BCH,
        };
        setTotalAddress(addresObj);
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // ACCESS DASHBOARD'S TOTAL TRANSACTIONS
  const fetchTransactions = () => {
    var endpoint = CASES_ENDPOINTS.dashboard.caseTransactions + 'biz_id=' + auth.get('biz_id');
    
    if (filterCaseStatus !== null && filterCaseType === null) {
      endpoint += '&status_id=' + filterCaseStatus;
    } else if (filterCaseStatus === null && filterCaseType !== null) {
      endpoint += '&case_type_id=' + filterCaseType;
    } else if (filterCaseStatus !== null && filterCaseType !== null) {
      endpoint +=
        '&status_id=' + filterCaseStatus + '&case_type_id=' + filterCaseType;
    }

    axios
      .get(endpoint, auth.getAuthConfig())
      .then(({ data }) => {
        var transactionObj = {
          title: 'Transaction Monitoring Activity',
          figureTitle: 'All-Time',
          figureSubTitle: 'Activity',
          figureValue: data.content[0].TotalTransactions,
          isBtc: true,
          btcValue: data.content[0].BTC,
          //btcPercentage: '(62%)',
          isEth: true,
          ethValue: data.content[0].ETH,
          //ethPercentage: '(22%)',
          isErc: true,
          ercValue: data.content[0]['ERC-20'],
          //ercPercentage: '(12%)',
          isLtc: true,
          ltcValue: data.content[0].LTC,
          //ltcPercentage: '(2%)',
          isBch: true,
          bchValue: data.content[0].BCH,
        };
        setTotalTransactions(transactionObj);
      })
      .catch((err) => {
        history.push('/login');
      });
  };

  useEffect(() => {
    updateParentImportModule('Cases')
    if (typeof window !== `undefined`) {
      localStorage.setItem('importModule', 'cases')
    }
    updateSortBy('TotalActivity');
    updateSortDir('DESC');
  }, []);

  useEffect(() => {
    fetchTotalCases();
    fetchAddresses();
    fetchTransactions();
    updateActiveModule('Cases');
  }, [newCase, filterCaseType, filterCaseStatus]);

  return (
    <div className="case-management">
      <Header />
      <div className="case-management-content">
        <div className="breakdown">
          <div className="container">
            <div className="breakdown-list">
              <BreakdownBlock breakdownValues={totalCase} />
              <BreakdownBlock breakdownValues={totalTransaction} />
              <BreakdownBlock breakdownValues={totalAddress} />
            </div>
          </div>
        </div>

        <div className="case-table">
          <Table />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CaseManagement);
