import React, { Fragment, useEffect, useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import moment from 'moment';
import classnames from 'classnames';
import axios from 'axios';
import { Link } from 'react-router-dom';

import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { Dates_Calendar } from '../helpers/datesCalendar';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';
import { numberWithCommas } from '../helpers/valueFormat';

const AccordionBody = ({ caseDetails }) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const { dataLoaded, activeModule } = useContext(Context);

  const [customerProfile, setCustomerProfile] = useState({});
  const history = useHistory();

  // ACCESS READ DETAIL CASE
  const fetchCustomerProfile = () => {
    if (caseDetails.Case_ID !== '0' && caseDetails.Case_ID !== `undefined`) {
      axios
        .get(
          CASES_ENDPOINTS.read.detailCase + 'case_id=' + caseDetails.Case_ID,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          if (data.content[0].Customer_ID !== undefined) {
            axios
              .get(
                CASES_ENDPOINTS.read.caseCustomer +
                  'customer_id=' +
                  data.content[0].Customer_ID,
                auth.getAuthConfig()
              )
              .then(({ data }) => {
                setCustomerProfile(data.content[0]);
                // console.log(data.content[0]);
              });
          }
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
            history.push('/login');
          }
        });
    } else {
      setCustomerProfile({
        Case_ID: 0,
        Business_ID: null,
        Customer_ID: null,
        First_Name: null,
        Middle_Name: null,
        Last_Name: null,
        Date_Birth: '0000-00-00',
        Company_Name: '',
        Country_Address: '',
        Nationality: '',
        Case_Since: '',
        Comments: '',
        Business_Name: '',
        Case_Type: '',
        Case_Type_ID: null,
        Country_Incorporation: '',
        Status: '',
        Status_ID: null,
        Case_Risk: '',
        Last_Modified: '0000-00-00 00:00:00',
        Sanction: '',
        Adverse_Media: '',
        PEP: '',
        AddressCount: null,
        TxCount: null,
      });
    }
  };

  useEffect(() => {
    fetchCustomerProfile();
    // console.log(caseDetails);
  }, [dataLoaded]);

  // ACCORDION PANEL UI
  return (
    <Fragment>
      <div className="accordion-panel-col multiple-col">
        <div className="col-wrapper">
          <p className="title">
            {activeModule === 'Whitelisting'
              ? 'Address Details'
              : activeModule === 'Monitoring'
              ? 'Transaction Details'
              : ''}
          </p> 
          {activeModule === 'Whitelisting' ? ( 

            // ADDRESS MONITORING TABLE#1 STARTS
            <ul> 
              <li>
                <p className="data-label">Latest Activity:</p>
                <p className="data-value">
                  {caseDetails.Latest_Activity === ''
                    ? 'Not Available'
                    : caseDetails.Latest_Activity}
                </p>
              </li>

              <li>
              <p className="data-label">Address Risk Score:</p>
              <p
                  className={`data-value${
                    caseDetails.Address_Risk_Score !== ''
                      ? ' with-score'
                      : ''
                  }`}
                >
                  {caseDetails.Address_Risk_Score !== '' ? (
                    <Fragment>
                      <span
                        className={classnames('risk-badge', {
                          high:
                            caseDetails.Address_Risk_Score >= 8 &&
                            caseDetails.Address_Risk_Score <= 10,
                          medium:
                            caseDetails.Address_Risk_Score >= 6 &&
                            caseDetails.Address_Risk_Score <= 7,
                          low:
                            caseDetails.Address_Risk_Score >= -1 &&
                            caseDetails.Address_Risk_Score <= 5,
                        })}
                      ></span>
                      {caseDetails.Address_Risk_Score}
                    </Fragment>
                  ) : (
                    '--'
                  )}
                </p>
              </li>
              
              <li>
                <p className="data-label">Flags:</p>
                <p className="data-value">{caseDetails.Flags}</p>
              </li>

              <li>
                <p className="data-label">Virtual Asset Name:</p>
                <p className="data-value">{caseDetails.Asset}</p>
              </li>

              {caseDetails.Balance !== undefined && (
                <li>
                  <p className="data-label">Address Balance:</p>
                  <p className="data-value eth-usd">
                    <span className="eth-usd-holder">
                      <span className="asset-value">
                        {caseDetails.Asset + ' '}
                      </span>
                      <span>{numberWithCommas(caseDetails.Balance)}</span>
                    </span>
                    <span className="divider">/</span>
                    <span className="eth-usd-holder">
                      <span className="asset-value">{'USD '}</span>
                      <span>{numberWithCommas(caseDetails.Balance_USD)}</span>
                    </span>
                  </p>
                </li>
              )}

              <li>
                <p className="data-label">Watched Address</p>
                <p className="data-value">No</p>
              </li>

              <li>
                <p className="data-label">Created by</p>
                <p className="data-value">{caseDetails.Created_By}</p>
              </li>

            </ul>
            // ADDRESS MONITORING TABLE#1 ENDS
          ) : (
            // TRANSACTION MONITORING TABLE#1 STARTS
            <ul>
              <li>
                <p className="data-label">Transaction Timestamp:</p>
                <p className="data-value">
                  {caseDetails.Latest_Activity === ''
                    ? 'Not Available'
                    : caseDetails.Tx_Timestamp}
                </p>
              </li>

              <li>
              <p className="data-label">Transaction Risk Score:</p>
                <p
                  className={`data-value${
                    caseDetails.Transaction_Risk_Score !== ''
                      ? ' with-score'
                      : ''
                  }`}
                >
                  {caseDetails.Transaction_Risk_Score !== '' ? (
                    <Fragment>
                      <span
                        className={classnames('risk-badge', {
                          high:
                            caseDetails.Transaction_Risk_Score >= 8 &&
                            caseDetails.Transaction_Risk_Score <= 10,
                          medium:
                            caseDetails.Transaction_Risk_Score >= 6 &&
                            caseDetails.Transaction_Risk_Score <= 7,
                          low:
                            caseDetails.Transaction_Risk_Score >= 0 &&
                            caseDetails.Transaction_Risk_Score <= 5,
                        })}
                      ></span>
                      {caseDetails.Transaction_Risk_Score}
                    </Fragment>
                  ) : (
                    ''
                  )}
                </p>
              </li>

              <li>
                <p className="data-label">Flags:</p>
                <p className="data-value">{caseDetails.Flags}</p>
              </li>

              <li>
                <p className="data-label">Virtual Asset Name:</p>
                <p className="data-value">{caseDetails.Asset}</p>
              </li>

              <li>
                <p className="data-label">Block Id:</p>
                <p className="data-value">{caseDetails.Block_ID}</p>
              </li>
              <li>
                <p className="data-label">Watched Transaction</p>
                <p className="data-value">No</p>
              </li>
              <li>
                <p className="data-label">Created by</p>
                <p className="data-value">{caseDetails.Created_By}</p>
              </li>
            </ul>
          // TRANSACTION MONITORING TABLE#1 ENDS  
        )} </div>
        
        <div className="col-wrapper">
          {activeModule === 'Whitelisting' ? (
          // ADDRESS MONITORING TABLE#2 STARTS
            <ul>
              
              <li>
                <p className="data-label"></p>
                <p className="data-label">Incoming</p>
                <p className="data-label">Outgoing</p>
              </li>
              
              <li>
                <p className="data-label">Total (Virtual Asset) </p>
                <p className="data-value eth-usd">
                  {caseDetails.Received && caseDetails.Received ? (
                    <Fragment>
                      <span className="eth-usd-holder">
                        <span className="asset-value">
                          {caseDetails.Asset + ' '}
                        </span>
                        <span>{numberWithCommas(caseDetails.Received)}</span>
                      </span>
                    </Fragment>
                  ) : (
                    '0' 
                  )}
                </p>
                <p className="data-value eth-usd">
                  {caseDetails.Spent && caseDetails.Spent ? (
                    <Fragment>
                      <span className="eth-usd-holder">
                        <span className="asset-value">
                          {caseDetails.Asset + ' '}
                        </span>
                        <span>{numberWithCommas(caseDetails.Spent)}</span>
                      </span>
                    </Fragment>
                  ) : (
                    '0'
                  )}
                </p>
              </li>
             
              <li>
                <p className="data-label">Total (USD)</p>
                <p className="data-value eth-usd">
                  {caseDetails.Received && caseDetails.Received_USD ? (
                    <Fragment>
                      <span className="eth-usd-holder">
                        <span className="asset-value">{'USD '}</span>
                        <span>{numberWithCommas(caseDetails.Received_USD)}</span>
                      </span>
                    </Fragment>
                  ) : (
                    '0'
                  )}
                </p>
                <p className="data-value eth-usd">
                  {caseDetails.Spent && caseDetails.Spent_USD ? (
                    <Fragment>
                      <span className="eth-usd-holder">
                        <span className="asset-value">{'USD '}</span>
                        <span>{numberWithCommas(caseDetails.Spent_USD)}</span>
                      </span>
                    </Fragment>
                  ) : (
                    '0'
                  )}
                </p>
              </li>
            </ul>
            // ADDRESS MONITORING TABLE#2 ENDS

          ) : (

            // TRANSACTION MONITORING TABLE#2 STARTS
            <ul>  
              <li>
                <p className="data-label"></p>
                <p className="data-label">Input</p>
                <p className="data-label">Output</p>
              </li>
              <li>
                <p className="data-label">Addresses</p>
                <p className="data-value eth-usd">
                  {caseDetails.Input_Count && caseDetails.Input_Count ? (
                    <Fragment>
                      <span className="eth-usd-holder">
                        <span>{numberWithCommas(caseDetails.Input_Count)}</span>
                      </span>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </p>

                <p className="data-value eth-usd">
                  {caseDetails.Output_Count && caseDetails.Output_Count ? (
                    <Fragment>
                      <span className="eth-usd-holder">
                        <span>{numberWithCommas(caseDetails.Output_Count)}</span>
                      </span>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </p>

              </li>

              <li>
                  <p className="data-label">Total (USD):</p>

                  <p className="data-value">
                    <span className="data-value">
                      <span className="asset-value">{'USD '}</span>
                      <span>{numberWithCommas(caseDetails.Input_USD)}</span>
                    </span>
                  </p>

                  <p className="data-label">
                    <span className="data-value">
                      <span className="asset-value">{'USD '}</span>
                      <span>{numberWithCommas(caseDetails.Output_USD)}</span>
                    </span>
                  </p>
              </li>              
            </ul>
            // TRANSACTION MONITORING TABLE#2 ENDS
          )}
        </div> 
      </div>



      <div className="accordion-panel-col">
        {caseDetails.Case_ID !== '0' && (          
          <div className="col-wrapper">
            {caseDetails.Case_ID !== '0' && (
              // ACCOUNT INFORMATION - NATURAL PERSON STARTS
              <p className="title">Account Information</p>
            )}
            {customerProfile.Case_Type === 'Natural Person' ? (
              <ul>

                <li>
                  <p className="data-label">Last Modified:</p>
                  <p className="data-value">
                    {customerProfile.Last_Modified === 'Invalid date'
                      ? 'Not Available'
                      : customerProfile.Last_Modified}
                  </p>
                </li>

                <li>
                  <p className="data-label">Account Risk: </p>
                  <p className={`data-value${ 
                    customerProfile.Case_Risk !== '' 
                    ? ' with-score' 
                    : '' }`} 
                  > 
                  {customerProfile.Case_Risk !== '' ? (
                  <Fragment> 
                    <span 
                    className={classnames('risk-badge', { 
                      high: 
                      customerProfile.Case_Risk == 'High', 
                      medium: 
                      customerProfile.Case_Risk == 'Medium', 
                      low: 
                      customerProfile.Case_Risk == 'Low', 
                    })} 
                    ></span> 
                    {customerProfile.Case_Risk} 
                    </Fragment> 
                  ) : ( 
                  '--' 
                  )} </p> 
                </li>

                <li>
                  <p className="data-label">Unique ID:</p>
                  <Link
                    to={`/case-management/details/${customerProfile.Customer_ID}`}
                    className="case-link"
                  >
                    <p className="data-value">{customerProfile.Customer_ID}</p>
                  </Link>
                </li>

                <li>
                  <p className="data-label">First Name:</p>
                  <p className="data-value">{customerProfile.First_Name}</p>
                </li>

                <li>
                  <p className="data-label">Last Name:</p>
                  <p className="data-value">{customerProfile.Last_Name}</p>
                </li>

                <li>
                  <p className="data-label">Primary Nationality:</p>
                  <p className="data-value">
                    {customerProfile.Nationality_Description}
                  </p>
                </li>

                <li>
                  <p className="data-label">Linked Addresses:</p>
                  <p className="data-value">{numberWithCommas(customerProfile.AddressCount)}</p>
                </li>

                <li>
                  <p className="data-label">Linked Transactions:</p>
                  <p className="data-value">{numberWithCommas(customerProfile.TxCount)}</p>
                </li>

                <li>
                  <p className="data-label">Account Type:</p>
                  <p className="data-value">
                    {customerProfile.Case_Type === 'Natural Person'
                      ? 'Natural Person'
                      : 'Individual'}
                  </p>
                </li>

                <li>
                  <p className="data-label">Account Status:</p>
                  <p className="data-value">{customerProfile.Case_Status}</p>
                </li>

                <li>
                  <p className="data-label">Account Since:</p>
                  <p className="data-value">
                    {moment(customerProfile.Case_Since).format(
                      Dates_Calendar.dateFormat
                    )}
                  </p>
                </li>

              </ul>
              
            ) : (
              ''
            )}


            
            {customerProfile.Case_Type === 'Legal Person' ? (
              // ACCOUNT INFORMATION - LEGAL PERSONS STARTS
              <ul>

                <li>
                  <p className="data-label">Last Modified:</p>
                  <p className="data-value">
                    {customerProfile.Last_Modified === 'Invalid date'
                      ? 'Not Available'
                      : customerProfile.Last_Modified}
                  </p>
                </li>

                <li>
                  <p className="data-label">Account Risk: </p>
                  <p className={`data-value${ 
                    customerProfile.Case_Risk !== '' 
                    ? ' with-score' 
                    : '' }`} 
                  > 
                  {customerProfile.Case_Risk !== '' ? (
                  <Fragment> 
                    <span 
                    className={classnames('risk-badge', { 
                      high: 
                      customerProfile.Case_Risk == 'High', 
                      medium: 
                      customerProfile.Case_Risk == 'Medium', 
                      low: 
                      customerProfile.Case_Risk == 'Low', 
                    })} 
                    ></span> 
                    {customerProfile.Case_Risk} 
                    </Fragment> 
                  ) : ( 
                  '--' 
                  )} </p> 
                </li>

                <li>
                  <p className="data-label">Unique ID:</p>
                  <Link
                    to={`/case-management/details/${customerProfile.Customer_ID}`}
                    className="case-link"
                  >
                    <p className="data-value">{customerProfile.Customer_ID}</p>
                  </Link>
                </li>
                
                <li>
                  <p className="data-label">Company Name:</p>
                  <p className="data-value">{customerProfile.Company_Name}</p>
                </li>

                <li>
                  <p className="data-label">Country of Incorporation:</p>
                  <p className="data-value">
                    {customerProfile.Country_Incorporation_Description}
                  </p>
                </li>

                <li>
                  <p className="data-label">Country of Domicile:</p>
                  <p className="data-value">
                    {customerProfile.Country_Address_Description}
                  </p>
                </li>

                <li>
                  <p className="data-label">Linked Addresses:</p>
                  <p className="data-value">{numberWithCommas(customerProfile.AddressCount)}</p>
                </li>

                <li>
                  <p className="data-label">Linked Transactions:</p>
                  <p className="data-value">{numberWithCommas(customerProfile.TxCount)}</p>
                </li>

                <li>
                  <p className="data-label">Account Type:</p>
                  <p className="data-value">
                    {customerProfile.Case_Type === 'Legal Person'
                      ? 'Legal Person'
                      : 'Entity'}
                  </p>
                </li>
               
                <li>
                  <p className="data-label">Account Status:</p>
                  <p className="data-value">{customerProfile.Case_Status}</p>
                </li>

                <li>
                  <p className="data-label">Account Since:</p>
                  <p className="data-value">
                    {moment(customerProfile.Case_Since).format(
                      Dates_Calendar.dateFormat
                    )}
                  </p>
                </li>

              </ul>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(AccordionBody);
