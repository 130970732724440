class Auth {

  // CHECK IF ALL REQUIRED TOKENS ARE AVAILABLE, RETURN LOGGED IN STATUS IF TRUE
  constructor() {
    if (typeof window !== `undefined`) {
      const userToken = localStorage.getItem('tokenZ')
      const businessID = localStorage.getItem('biz_id')
      const userID = localStorage.getItem('user_id')
      const fullname = localStorage.getItem('fullname')
      const username = localStorage.getItem('username')
      const role = localStorage.getItem('role')
      
      if (userToken && businessID && userID && fullname && username && role) {
        this.authenticated = true;
      }
    }
  }

  // SAVE USER'S AVAILABLE TOKENS
  login(token, businessID, userID, fullName, username, role, callback) {
    if (typeof window !== `undefined`) {
      localStorage.setItem('tokenZ', token)
      localStorage.setItem('biz_id', businessID)
      localStorage.setItem('user_id', userID)
      localStorage.setItem('fullname', fullName)
      localStorage.setItem('username', username)
      localStorage.setItem('role', role)
      localStorage.setItem('reportStrings', JSON.stringify(['A']));
      localStorage.setItem('reportTitles', JSON.stringify(['A']));
      this.authenticated = true;
  
      callback() 
    }
  }

  // RETURN A USER'S TOKEN, DEPENDS ON THE ATTRIBUTE PARAMETER
  get(attribute) {
    if(typeof window !== `undefined`) {
      return localStorage.getItem(attribute)
    }
  }

  // RETURN USER'S AUTHENTICATION TOKEN
  getAuthConfig() {
    if (typeof window !== `undefined`) {
      if (this.authenticated) {
        return {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenZ')}`
          }
        }
      }
    }
  }

  // DESTROY ALL USER'S AVAILABLE TOKEN
  logout() {
    if (typeof window !== `undefined`) {
      localStorage.clear()
      this.authenticated = false;
    }
  }

  // RETURNS TRUE IF USER IS LOGGED IN
  isAuthenticated() {
    return this.authenticated;
  }
}

export default new Auth()