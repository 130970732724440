import React from 'react';

const Illustration = ({ addClass, show, image, heading, description }) => {
  return (
    <div
      className={`table-illustration${addClass !== '' ? ' ' + addClass : ''}`}
    >
      <div className="table-illustration-wrapper">
        <div className="img-holder">
          <img src={image} alt="Create First Case" />
        </div>
        <h2 className="text-heading">{heading}</h2>
        <p className="text-description">{description}</p>
      </div>
    </div>
  );
};

export default Illustration;
