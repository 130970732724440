import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

//routes for pages that requires user login
import ProtectedRoute from './helpers/protected.route';

//pages
import sheetUpload from './containers/csv-dropzone';
import CaseManagement from './containers/case-management';
import Monitoring from './containers/monitoring';
import TxDetails from './containers/tx-details';
import Home from './containers/home';
import Login from './containers/login';
import CaseDetails from './containers/case-details';
import CaseAddresses from './containers/case-addresses';
import CaseTransactions from './containers/case-transactions';
import RecoverPassword from './containers/recover-password';
import ResetPassword from './containers/reset-password';
import AccountActivation from './containers/account-activation';

//components
import Assign from './components/assign.js';
import UserProfile from './containers/user-profile.js';
import SearchBar from './components/SearchBar';
import TableRow from './components/TableRow';

// contexts
import ContextProvider from './contexts/Context';

//temporary
import Global from './containers/global';

import './styles/main.scss';

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ContextProvider>
        <div className="App">
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/search" exact component={SearchBar} />
            <Route path="/assign" exact component={Assign} />

            {/* Pages that require user login */}
            
            <ProtectedRoute 
              exact path="/whitelisting" 
              component={Home} 
            />

            <ProtectedRoute 
              exact path="/monitoring" 
              component={Monitoring} 
            />

            <ProtectedRoute 
              exact path="/" 
              component={CaseManagement} 
            />

            <ProtectedRoute
              exact
              path="/user-profile"
              component={UserProfile}
            />

            <ProtectedRoute
              exact
              path="/case-management/details/:customerId+"
              component={CaseDetails}
            />

            <ProtectedRoute
              exact
              path="/case-management/addresses/:customerId+"
              component={CaseAddresses}
            />

            <ProtectedRoute
              exact
              path="/case-management/transactions/:customerId+"
              component={CaseTransactions}
            />

            <ProtectedRoute 
              exact path="/sheetjs" 
              component={sheetUpload} /
            >

            <ProtectedRoute 
              exact path="/rows" 
              component={TableRow} 
            />

            <ProtectedRoute 
              exact path="/global" 
              component={Global} 
            />

            <ProtectedRoute
              exact
              path="/tx-details/:hash+"
              component={TxDetails}
            />

            <ProtectedRoute
              exact
              path="/recover-password"
              component={RecoverPassword}
            />

            <ProtectedRoute
              exact
              path="/reset-password"
              component={ResetPassword}
            />

            <ProtectedRoute
              exact
              path="/account-activation"
              component={AccountActivation}
            />

          </Switch>
        </div>
      </ContextProvider>
    </BrowserRouter>
  );
}

export default App;
