import React, { useState, useEffect, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import axios from "axios";
import { MONITORING_ENDPOINTS } from "../helpers/urls";

import Header from "../components/header";

import auth from "../helpers/auth";
import response from '../helpers/responseChecker';
import getRiskScoreColor from '../helpers/getRiskScoreColor';
import { Context } from "../contexts/Context";
import NodeChart from "../components/NodeChart";

const TxDetails = (props) => {
  // Props Destructure
  const { location: { details }, history } = props;

  // Context
  const { updateIsLoadingState } = useContext(Context);

  // States
  const [ investigationData, setInvestigationData ] = useState(null);

  // Variables
  const INVESTIGATION_URL = MONITORING_ENDPOINTS.txInvestigation;

  // Functions
  const getChainName = (args) => args === 'ETH' ? 'ethereum' : args === 'BTC' ? 'bitcoin' : ''

  /** Fetch investigation data
   * @param  {} {type Type of investigation data (transaction/address)
   * @param  {} asset Name of asset
   * @param  {} limit Data number limit
   * @param  {} direction Node data direction
   * @param  {} transaction Transaction Hash
   * @param  {} address} Address Hash
   */
  const fetchInvestigationChartData = ({ type, asset, limit, direction, transaction, address }) => {
 
    if (asset === "BTC" || asset === "ETH") {
      const chain = getChainName(asset)
      
      const cipher = type === 'transaction' ? transaction : address

      const params = {
        cipher,
        type,
        limit,
        chain,
        direction
      }

      return axios
        .get(INVESTIGATION_URL, { params: params, ...auth.getAuthConfig() })
        .then(({ data }) => {
          if (data) {
            const validator = response.validate(data.code[0], data.message[0]);

            if (validator) return data.content.map(item => {

              item.value = item.amount || item.balance || 0
              item.hash = item[item.type]

              return ({ ...item, direction, children: [] })
            })
            
            return 'error'
          }
        })
        .catch((error) => {
          return error
        });
    } else {
      return []
    }
  }

  // useEffect Hooks
  useEffect(() => {
    if ( details ) {
      const { type, data } = details

      const params = {}
      const rootNodeData = {}

      if (type === 'Whitelisting') {
        params.address = data.Address
        params.type = 'address'   
        params.limit = 5
        params.asset = data.Asset

        rootNodeData.type =  'address'
        rootNodeData.hash =  data.Address
        rootNodeData.asset =  data.Asset
        rootNodeData.value =  data.Balance
        rootNodeData.balanceUSD =  data.Balance_USD
        rootNodeData.riskScore =  data.Address_Risk_Score
        rootNodeData.color =  getRiskScoreColor(data.Address_Risk_Score)
        rootNodeData.flag =  data.Flags
        rootNodeData.lastActivity =  data.Latest_Activity
        rootNodeData.children =  []
        
      } else if (type === 'Monitoring') {
        params.transaction = data.Hash
        params.type = 'transaction'
        params.limit = 5
        params.asset = data.Asset
        
        rootNodeData.type =  'transaction'
        rootNodeData.hash =  data.Hash
        rootNodeData.asset =  data.Asset
        rootNodeData.value =  data.Output_USD
        rootNodeData.amountUSD =  data.Output_USD
        rootNodeData.riskScore =  data.Transaction_Risk_Score
        rootNodeData.color =  getRiskScoreColor(data.Transaction_Risk_Score)
        rootNodeData.flag =  data.Flags
        rootNodeData.timestamp =  data.Tx_Timestamp
        rootNodeData.children =  []
      }

      const incomingParams = { ...params, direction: 'incoming' }
      const outgoingParams = { ...params, direction: 'outgoing' }

      Promise.all([
          fetchInvestigationChartData(incomingParams),
          fetchInvestigationChartData(outgoingParams)
        ])
        .then((response) => {
          const [incomingData, outgoingData] = response

          if (!incomingData[0].Status && !outgoingData[0].Status) {

            updateIsLoadingState(false)
            
            const incoming = [{ ...rootNodeData, children: incomingData }]
            const outgoing = [{ ...rootNodeData, children: outgoingData }]
            
            setInvestigationData({
              type,
              incoming,
              outgoing
            })
          } else {
            alert('Error fetching investigation data. Please try again.')
            
            history.goBack()
          }

        })
        .catch((error) => {
          alert('Error fetching investigation data. Please try again.')

          history.goBack()
          
          console.log(error)
        })
    }
  }, [])

  return (
    <>
      <div className="case-management">
        <Header />
          <div className="case-table">
          {investigationData && <NodeChart data={investigationData} fetchChildren={fetchInvestigationChartData}/>}
          </div>
      </div>
    </>
  );
};

export default withRouter(TxDetails);
