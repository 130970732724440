import React, { useEffect, useState, useContext } from 'react';
import { Link, withRouter, useHistory } from 'react-router-dom';

//Helpers
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import moment from 'moment';

//Icons
import Entity from '../assets/images/icon-entity.svg';
import Individual from '../assets/images/icon-individual.svg';
import ExternalLink from '../assets/images/icon-link.svg';

import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { Dates_Calendar, LineChartDateFormat } from '../helpers/datesCalendar';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { Colour_Schemes } from '../helpers/colourSchemes';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const TableRow = ({ caseItem }) => {
  const [wlActivities, setWlActivities] = useState({});
  const [txActivities, setTxActivities] = useState({});

  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    newFilter,
    pageSize,
    pageIndex,
    sortBy,
    sortDir,
  } = useContext(Context);

  const history = useHistory();

  useEffect(() => {
    // ACCESS CASE ACTIVITIES
    const readCaseActivities = () => {
      axios
        .get(
          CASES_ENDPOINTS.read.caseActivities +
            'case_id=' +
            caseItem.Case_ID +
            '&start_range=' +
            moment().subtract(30, 'days').format(Dates_Calendar.dateFormat) +
            '&end_range=' +
            moment().add(1, 'days').format(Dates_Calendar.dateFormat),
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          
          console.log(caseItem.Case_ID  ,getDates(data.content))
          if (data.content[0].length > 0) {
            setTxActivities({
              datasets: [
                {
                  data: getDates(data.content[0]),
                  backgroundColor: 'transparent',
                  borderColor: Colour_Schemes.transactionActivitiesLineColour,
                  borderWidth: '2',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                },
              ],
              labels: [],
            });
          } else if (data.content[0].length === undefined) {
            setTxActivities({
              datasets: [
                {
                  data: [
                    {
                      y: 0,
                      t: 0,
                    },
                    {
                      y: 0,
                      t: 50,
                    },
                  ],
                  backgroundColor: 'transparent',
                  borderColor: Colour_Schemes.transactionActivitiesLineColour,
                  borderWidth: '2',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                },
              ],
              labels: [],
            });
          }

          if (data.content[1].length > 0) {
            setWlActivities({
              datasets: [
                {
                  data: getDates(data.content[1]),
                  backgroundColor: 'transparent',
                  borderColor: Colour_Schemes.whitelistingActivitiesLineColour,
                  borderWidth: '2',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                },
              ],
              labels: [],
            });
          } else {
            setWlActivities({
              datasets: [
                {
                  data: [
                    {
                      y: 0,
                      t: 0,
                    },
                    {
                      y: 0,
                      t: 50,
                    },
                  ],
                  backgroundColor: 'transparent',
                  borderColor: Colour_Schemes.whitelistingActivitiesLineColour,
                  borderWidth: '2',
                  pointRadius: 0,
                  pointHoverRadius: 0,
                },
              ],
            });
          }
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
            history.push('/login');
          }
        });
    };

    // GET DATES THAT ARE AVAILABLE IN BETWEEN TWO DATES
    const getDates = (data) => {
      var days = [];
      var currentDate = null;
      var stopDate = null;

      var x = moment().subtract(LineChartDateFormat.dateRange, 'days').format(Dates_Calendar.dateFormat);
      var y = moment().format(Dates_Calendar.dateFormat);

      currentDate = moment(x);
      stopDate = moment(y);

      while (currentDate <= stopDate) {
        days.push({ t: moment(currentDate).format(LineChartDateFormat.format) });
        currentDate = moment(currentDate).add(1, 'days');
      }

      let copyArr = days;

      data.forEach((result) => {
        copyArr.forEach((item, i) => {
          if (result.t === item.t) {
            days[i].y = result.y;
          }
        });
      });

      days.forEach((item, i) => {
        if (item.y === undefined) {
          days[i].y = 0;
        }
      });

      return days;
    };

    readCaseActivities();
  }, [pageIndex, pageSize, sortBy, sortDir, caseItem, newFilter]);

  return (
    <>
      <div className="row-wrapper">
        <li className="table-row table-row-hd">
          {caseItem.Case_Type === 'Legal Person' ? (
            <img src={Entity} alt="ospree-legal" />
          ) : (
            <img src={Individual} alt="ospree-natural" />
          )}
        </li>
        <ul className="table-data">
          <li className="table-row table-row-hd case-type">
            {caseItem.Case_Type}
          </li>
          <li className="table-row table-row-hd customer-id">
            {caseItem.Customer_ID}
          </li>
          <li className="table-row table-row-hd status">
            {caseItem.Case_Status}
          </li>
          <li className="table-row table-row-hd tx-count">
            {caseItem.TxCount}
            <div className="line-chart-wrapper">
              {/* FEED LINE CHART PARAMETERS */}
              <Line
                data={txActivities}
                options={{
                  responsive: true,
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: false,
                  },
                  layout: {
                    padding: {
                      bottom: 14,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        type: 'time',
                        gridLines: {
                          color: Colour_Schemes.transactionActivitiesBGColour,
                          display: false,
                          lineWidth: 0,
                        },
                        display: false,
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          color: Colour_Schemes.transactionActivitiesBGColour,
                          display: false,
                          lineWidth: 0,
                        },
                        ticks: {
                          display: false,
                          beginAtZero: true,
                          min: 0,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </li>
          <li className="table-row table-row-hd address-count">
            {caseItem.AddressCount}
            <div className="line-chart-wrapper">
              {/* FEED LINE CHART PARAMETERS */}
              <Line
                data={wlActivities}
                options={{
                  responsive: true,
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: false,
                  },
                  layout: {
                    padding: {
                      bottom: 14,
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        type: 'time',
                        gridLines: {
                          color: Colour_Schemes.whitelistingActivitiesBGColour,
                          display: false,
                          lineWidth: 0,
                        },
                        display: false,
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          color: Colour_Schemes.whitelistingActivitiesBGColour,
                          display: false,
                          lineWidth: 0,
                        },
                        ticks: {
                          display: false,
                          beginAtZero: true,
                          min: 0,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
          </li>
          <li className="table-row table-row-hd">
            <Link
              to={`/case-management/details/${caseItem.Customer_ID}`}
              className="case-link"
            >
              <img src={ExternalLink} alt="ospree-link" />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default withRouter(TableRow);
