import React, { useState, useContext, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { saveAs } from 'file-saver';
import auth from '../helpers/auth';
import classnames from 'classnames';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from 'reactstrap';

import { Context } from '../contexts/Context';
import { External_URLs } from '../helpers/externalUrls';

import SubHeader from '../components/CaseManagementHeader';

const Header = (props) => {
    // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    isLoading,
    notifToggle,
    globalNotifToggler,
    isProfileUpdated,
    updateSortBy,
    updateSortDir,
    updateActiveModule,
    updateGlobalNotifToggler,
    dropzoneModal,
    closeDropZone,
  } = useContext(Context);

  const [fullname, setfullname] = useState('');
  const [username, setusername] = useState('');
  const [role, setrole] = useState('');
  const [reportString, setReportString] = useState([]);
  const [reportTitle, setReportTitle] = useState([]);

  // GET WHAT TYPE OF IMPORT MODULE ARE YOU IN, CAN BE CASES, ADDRESSES, TRANSACTIONS
  var importActiveModule = '';
  if (typeof window !== `undefined`) {
    importActiveModule = localStorage.getItem('importModule');
  }

  // SET DEFAULT SORT SETTINGS PER MODULE
  const resetSortBy = (newActiveModule) => {
    updateSortBy('CreateTimestamp');
    updateSortDir('DESC');
    updateActiveModule(newActiveModule);
  };

  // DOWNLOAD REPORT
  const downloadReport = (index) => {
    saveAs(
      new Blob([s2ab(reportString[index])], {
        type: 'application/octet-stream',
      }),
      reportTitle[index]
    );
  };

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      setfullname(localStorage.getItem('fullname'));
      setusername(localStorage.getItem('username'));
      setrole(localStorage.getItem('role'));
      setReportString(
        JSON.parse(localStorage.getItem('reportStrings')).reverse()
      );
      setReportTitle(
        JSON.parse(localStorage.getItem('reportTitles')).reverse()
      );
    }
  }, [isProfileUpdated, notifToggle, globalNotifToggler]);

  return (
    <>
      <header className="header">
        <div className="header-wrapper">
          <Link to="/" className="navbar-brand">
            <img
              src={require('../assets/images/brand-logo.png')}
              className="logo-img img-fluid"
              alt="logo"
            />
          </Link>
          <div className="navbar-nav">
            <ul className="menu-list">
              <li
                className="menu-item"
                onClick={() => {
                  updateSortBy('TotalActivity');
                  updateSortDir('DESC');
                  closeDropZone();
                }}
              >
                <NavLink
                  exact
                  activeClassName="active"
                  className={`menu-link ${
                    props.customerId && props.customerId.length !== 0
                      ? 'active'
                      : ''
                  }`}
                  to="/"
                >
                  Case Management 
                  
                </NavLink>
              </li>
              <li
                className="menu-item"
                onClick={() => {
                  resetSortBy('Whitelisting');
                  closeDropZone();
                }}
              >
                <NavLink
                  exact
                  activeClassName="active"
                  className="menu-link"
                  to="/whitelisting"
                >
                  Address Monitoring
                </NavLink>
              </li>
              <li
                className="menu-item"
                onClick={() => {
                  resetSortBy('Monitoring');
                  closeDropZone();
                }}
              >
                <NavLink
                  exact
                  activeClassName="active"
                  className="menu-link"
                  to="/monitoring"
                >
                  Transaction Monitoring
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="user-logged">
            <Dropdown
              isOpen={globalNotifToggler}
              toggle={() => updateGlobalNotifToggler(!globalNotifToggler)}
              className="option-holder"
            >
              <DropdownToggle
                caret
                direction="down"
                color="link"
                className="btn btn-user-menu"
              >
                <img
                  src={require('../assets/images/notification-bell.svg')}
                  className="icon"
                  alt="user-2 icon"
                />
                <div
                  className={classnames({
                    hasNotif:
                      reportString !== null && reportString.length !== 1,
                  })}
                >
                  {reportString !== null && reportString.length !== 1 && '!'}
                </div>
              </DropdownToggle>
              {
                <DropdownMenu>
                  {reportTitle !== null && reportTitle.length > 1 && (
                    <DropdownItem className="notification">
                      <p>Data import completed</p>
                      <p className="dropdown-list download-text">
                        Download the report
                      </p>
                    </DropdownItem>
                  )}
                  {reportTitle !== null && reportTitle.length > 1 ? (
                    reportTitle.map((item, i) => {
                      return (
                        reportTitle[i] !== 'A' && (
                          <DropdownItem
                            key={i}
                            className="notification"
                            onClick={() => {
                              downloadReport(i);
                            }}
                          >
                            <p className="dropdown-list download-text">
                              {item.substring(0, 19)}...
                            </p>
                          </DropdownItem>
                        )
                      );
                    })
                  ) : (
                    <DropdownItem className="notification">
                      <p> No notification. </p>
                    </DropdownItem>
                  )}
                </DropdownMenu>
              }
            </Dropdown>

            <UncontrolledDropdown className="option-holder">
              <DropdownToggle
                caret
                direction="down"
                color="link"
                className="btn btn-user-menu"
              >
                <span className="menu-icon" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <a
                    href={External_URLs.feedback}
                    target="blank"
                    className="dropdown-list"
                  >
                    Send feedback
                  </a>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown className="profile-dropdown">
              <DropdownToggle
                caret
                direction="down"
                color="link"
                className="image-holder"
              >
                <div className="user-icon-wrapper">
                  <img
                    src={require('../assets/images/user.svg')}
                    className="icon"
                    alt="user-2 icon"
                  />
                </div>
              </DropdownToggle>
              <DropdownMenu>
                <div className="profile-info">
                  <h4 className="profile-fullname">{fullname}</h4>
                  <p className="profile-user-name">{username}</p>
                  <p className="profile-user-role">{role}</p>
                </div>
                <DropdownItem>
                  <Link
                    to="/user-profile"
                    className="dropdown-list"
                    onClick={closeDropZone}
                  >
                    <img
                      src={require('../assets/images/user-icon.svg')}
                      className="icon"
                      alt="user icon"
                    />
                    Profile
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to="/login"
                    className="dropdown-list"
                    onClick={() => {
                      auth.logout();
                    }}
                  >
                    <img
                      src={require('../assets/images/log-out.svg')}
                      className="icon"
                      alt="logout icon"
                    />
                    Sign out
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {(importActiveModule === 'transaction' ||
          importActiveModule === 'addresses' ||
          importActiveModule === 'details') &&
          !dropzoneModal &&
          props.customerId &&
          props.customerId.length !== 0 && (
            <SubHeader customerId={props.customerId} />
          )}
        <div
          className={classnames('custom-bar-wrapper', {
            loading: isLoading,
          })}
        >
          <div className="custom-bar">
            <div></div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
