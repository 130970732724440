import React, { useState } from 'react';
import axios from 'axios';

import auth from '../helpers/auth';
import response from '../helpers/responseChecker';
import { External_URLs } from '../helpers/externalUrls';
import { ACCOUNTS_ENDPOINT } from '../helpers/urls';
import { LOGIN_ENDPOINT } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const ResetPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userCredentials, setUserCredentials] = useState({
    newPassword: '',
    confirmPassword: '',
  });

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserCredentials({
      ...userCredentials,
      [name]: value.replace(/\s/g, ''),
    });
  };

  // SEND STATE VARIABLES' VALUES TO THE API
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (userCredentials.newPassword !== userCredentials.confirmPassword) {
      setErrorMsg("Password confirmation didn't match!")
      return
    }

    const payload = {
      user_id: localStorage.getItem('user_id'),
      new_password: userCredentials.newPassword
    }

    axios
      .put(ACCOUNTS_ENDPOINT.resetPassword, payload, auth.getAuthConfig())
      .then(({ data }) => {
        setLoading(false);
        const validator = response.validate(data[0].code, data[0].message);
        if (validator) {
          props.history.push('/');
        } else {
          setErrorMsg(data[0].message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data[0].code === RESPONSE_CODES.httpBadRequest) {
          setLoading(false);
          setErrorMsg(error.response.data[0].message);
        }
      });

  };

  return (
    <>
      <header className="reset-password-header">
        <div className="container reset-password-container">
          <a href={External_URLs.mainSite}>
            <img
              src={require('../assets/images/brand-logo-white.png')}
              className="reset-password-logo"
              alt="reset-password-logo"
            />
          </a>
        </div>
      </header>

      <div className="reset-password">
        <div className="container reset-password-container">
          <div className="reset-password-wrapper">
            <div className="form-wrapper">
              <div className="form-content">
                <h1 className="reset-password-title">Reset Password</h1>
                <p className="reset-password-description">Please enter updated password below</p>
                <form className="form" onSubmit={handleSubmit}>
                  <label htmlFor="new-password" className="subtitle">
                    New Password
                  </label>
                  <input
                    type="password"
                    name="newPassword"
                    id="new-password"
                    onChange={handleData}
                  />
                  <label htmlFor="confirm-password" className="subtitle">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirm-password"
                    onChange={handleData}
                  />
                  <button
                    type="submit"
                    className="btn-custom btn-blue btn-hover-violet"
                  >
                    {loading ? 'Loading...' : 'Submit'}
                  </button>
                  <p className="error-message">{errorMsg}</p>
                </form>
              </div>
              <a
                href={External_URLs.privacyPolicy}
                className="privacy-terms-link"
              >
                Privacy Policy • Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
