import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import { Context } from '../contexts/Context'

const CaseManagementHeader = (props) => {
  const {
    updateSortBy
  } = useContext(Context)

  return (
    <div className="sub-header">
      <div className="sub-header-wrapper">
        <ul className="sub-header-link-holder">
          <li className="sub-header-item">
            <NavLink
              exact
              activeClassName="active"
              className="sub-header-link"
              to={`/case-management/details/${props.customerId}`}
            >
              Details
            </NavLink>
          </li>
          <li className="sub-header-item">
            <NavLink
              exact
              activeClassName="active"
              className="sub-header-link"
              onClick={() => {
                updateSortBy('CreateTimestamp')
              }}
              to={`/case-management/addresses/${props.customerId}`}
            >
              Addresses
            </NavLink>
          </li>
          <li className="sub-header-item">
            <NavLink
              exact
              activeClassName="active"
              className="sub-header-link"
              onClick={() => {
                updateSortBy('CreateTimestamp')
              }}
              to={`/case-management/transactions/${props.customerId}`}
            >
              Transactions
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CaseManagementHeader;
