import React from 'react'
import { numberWithCommas } from '../helpers/valueFormat';

export const BreakdownBlock = ({breakdownValues}) => {
  // DASHBOARD UI
  return (
    <div className="breakdown-block">
      <p className="breakdown-title">{breakdownValues.title}</p>
      <div className="figure-wrapper">
        
        <p className="figure-value">{numberWithCommas(breakdownValues.figureValue)}</p>
        <div className="figure-head">
          <p className="figure-title">{breakdownValues.figureTitle}</p>
          <p className="figure-subtitle">{breakdownValues.figureSubTitle}</p>
        </div>
      </div>
      
      { breakdownValues.isEntity &&
        <div className="bdown-values">
          <p className="breakdown-string">Legal Person (Entity)</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.entityValue)}</p>
          </div>
        </div>
      }

      
      { breakdownValues.isEntity &&
        <div className="bdown-values">
          <p className="breakdown-string">Natural Person (Individual)</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.individualValue)}</p>
          </div>
        </div>
      }

      { breakdownValues.isBtc &&
        <div className="bdown-values">
          <p className="breakdown-string">BTC</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.btcValue)}</p>
          </div>
        </div>
      }

      { breakdownValues.isEth &&
        <div className="bdown-values">
          <p className="breakdown-string">ETH</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.ethValue)}</p>
          </div>
        </div>
      }

      { breakdownValues.isErc &&
        <div className="bdown-values">
          <p className="breakdown-string">ERC-20</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.ercValue)}</p>
          </div>
        </div>
      }

      { breakdownValues.isLtc &&
        <div className="bdown-values">
          <p className="breakdown-string">LTC</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.ltcValue)}</p>
          </div>
        </div>
      }

      { breakdownValues.isBch &&
        <div className="bdown-values">
          <p className="breakdown-string">BCH</p>
          <div className="breakdown-figure-wrapper">
            <p className="breakdown-value">{numberWithCommas(breakdownValues.bchValue)}</p>
          </div>
        </div>
      }
    </div>
  )
}

export default BreakdownBlock