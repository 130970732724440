import React, { createContext, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';
import response from '../helpers/responseChecker';
import auth from '../helpers/auth';
import { Dates_Calendar } from '../helpers/datesCalendar';
import { CASES_ENDPOINTS } from '../helpers/urls';

export const Context = createContext();
export const ContextProvider = ({ children }) => {
  // ALL AVAILABLE GLOBAL VARIABLES

  var base = new Date();
  base.setDate(base.getDate() + 1);
  var end = base.toJSON().slice(0, 10).replace(/-/g, '-');
  base.setDate(base.getDate() - Dates_Calendar.dateRange);
  var start = base.toJSON().slice(0, 10).replace(/-/g, '-');

  const history = useHistory();

  const [dateRange, setDateRange] = useState({
    startDate: start,
    endDate: end,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSorBy] = useState('AddressCount');
  const [sortDir, setSortDir] = useState('DESC');
  const [removing, setRemoving] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [searchState, setSearchState] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pageTotalItems, setPageTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [newCase, setNewCase] = useState(false);
  const [newFilter, setNewFilter] = useState(false);
  const [activeModule, setActiveModule] = useState('Whitelisting');
  const [notifToggle, setNotifToggle] = useState(false);
  const [globalNotifToggler, setGlobalNotifToggler] = useState(false);
  const [countries, setCountries] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [dropzoneModal, setDropzoneModal] = useState(false);
  const [filterCaseType, setFilterCaseType] = useState(null);
  const [filterCaseStatus, setFilterCaseStatus] = useState(null);
  const [parentImportModule, setParentImportModule] = useState('');
  const [searchFlag, setSearchFlag] = useState(true);

  const updateTotalCount = (totalCountUpdated) => {
    setTotalCount(totalCountUpdated);
  };

  const updatePageIndex = (pageIndexUpdated) => {
    setPageIndex(pageIndexUpdated);
  };

  const updatePageSize = (pageSizeUpdated) => {
    setPageSize(pageSizeUpdated);
  };

  const updateDateRange = (pickerStartDate, pickerEndDate) => {
    setDateRange({ startDate: pickerStartDate, endDate: pickerEndDate });
  };

  const updateSearchTerm = (searchTerm) => {
    setSearchTerm(searchTerm);
  };

  const updateSortBy = (newSortBy) => {
    setSorBy(newSortBy);
  };

  const updateSortDir = (newSortDir) => {
    setSortDir(newSortDir);
  };

  const updateRemoving = (newState) => {
    setRemoving(newState);
  };

  const updateAssigning = (newState) => {
    setAssigning(newState);
  };

  const updateSearchState = (newState) => {
    setSearchState(newState);
  };

  const updateIsLoadingState = (newState) => {
    setIsLoading(newState);
  };

  const updatePageTotalItems = (newState) => {
    setPageTotalItems(newState);
  };

  const updateTotalPages = (newState) => {
    setTotalPages(newState);
  };

  const updateIsProfileUpdated = (newState) => {
    setIsProfileUpdated(newState);
  };

  const updateActiveModule = (newState) => {
    setActiveModule(newState);
  };

  const updateDataLoaded = (newState) => {
    setDataLoaded(!dataLoaded);
  };

  const updateNewCase = (newState) => {
    setNewCase(newState);
  };

  const updateNewFilter = () => {
    setNewFilter(!newFilter);
  };

  const updateNotifToggle = () => {
    setNotifToggle(!notifToggle);
  };

  const updateGlobalNotifToggler = (newState) => {
    setGlobalNotifToggler(newState);
  };

  const updateSearchFlag = (newState) => {
    setSearchFlag(newState);
  };

  const fetchCountries = () => {
    axios
      .get(
        CASES_ENDPOINTS.countries,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        const validator = response.validate(data.code[0], data.message[0]);

        if (validator) {
          setCountries(data.content);
        }
      })
      .catch((err) => {
        if (err.response.data.code[0] === 401) {
          history.push('/login');
        }
      });
  };

  const updateUploadModal = () => {
    setUploadModal(false);
    // setModalChecker(false);
  };

  const updateUploadModalOpen = () => {
    setUploadModal(true);
  };

  const closeDropZone = () => {
    setDropzoneModal(false);
    // setModalChecker(false);
  };

  const toggleDropZone = () => {
    setDropzoneModal(!dropzoneModal);
    // setModalChecker(!modalChecker);
  };

  const updateFilterCaseType = (newState) => {
    setFilterCaseType(newState);
  };

  const updateFilterCaseStatus = (newState) => {
    setFilterCaseStatus(newState);
  };

  const updateParentImportModule = (newState) => {
    setParentImportModule(newState);
  };

  return (
    <Context.Provider
      value={{
        searchTerm,
        dateRange,
        sortBy,
        sortDir,
        removing,
        assigning,
        pageIndex,
        pageSize,
        totalCount,
        totalPages,
        searchState,
        isLoading,
        notifToggle,
        isProfileUpdated,
        pageTotalItems,
        activeModule,
        dataLoaded,
        newCase,
        newFilter,
        globalNotifToggler,
        countries,
        uploadModal,
        dropzoneModal,
        filterCaseType,
        filterCaseStatus,
        parentImportModule,
        searchFlag,
        updateDateRange,
        updateSearchTerm,
        updateSortBy,
        updateSortDir,
        updateRemoving,
        updateAssigning,
        updatePageIndex,
        updatePageSize,
        updateTotalCount,
        updateSearchState,
        updateIsLoadingState,
        updateIsProfileUpdated,
        updatePageTotalItems,
        updateTotalPages,
        updateActiveModule,
        updateDataLoaded,
        updateNewCase,
        updateNewFilter,
        updateNotifToggle,
        updateGlobalNotifToggler,
        fetchCountries,
        updateUploadModal,
        toggleDropZone,
        closeDropZone,
        updateUploadModalOpen,
        updateFilterCaseType,
        updateFilterCaseStatus,
        updateParentImportModule,
        updateSearchFlag,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export default withRouter(ContextProvider);
