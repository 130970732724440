// EXPECTS UNIFORM OBJECT STRUCTURE
export default (args) => {
    
  if (args >= 0 && args < 6) {
    return '#89CF82'
  } else if (args > 5 && args < 8) {
    return '#F9E123'
  } else if (args > 7 && args < 11) {
    return '#F45C65'
  }

}