import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

// Helpers
import classnames from 'classnames';
import axios from 'axios';
import { ReactDatez } from 'react-datez';
import moment from 'moment';
import Pagination from 'react-js-pagination';

import PlainSearchBar from './Plain-Searchbar';

import response from '../helpers/responseChecker';
import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

// Reactstrap
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  FormGroup,
  InputGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import { Dates_Calendar } from '../helpers/datesCalendar';

//Icons
import Menu from '../assets/images/menu.svg';
import Filter from '../assets/images/filter.svg';

//Components
import TableRow from './TableRow';
import Illustration from './Illustration';
import UploadWindow from './UploadWindow';

const Table = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    sortBy,
    sortDir,
    newCase,
    pageIndex,
    isLoading,
    pageTotalItems,
    pageSize,
    filterCaseType,
    searchState,
    searchTerm,
    filterCaseStatus,
    updatePageSize,
    updatePageTotalItems,
    updateTotalPages,
    updatePageIndex,
    updateIsLoadingState,
    updateNewCase,
    updateNewFilter,
    updateFilterCaseType,
    updateFilterCaseStatus,
    updateSearchTerm,
    updateSearchState,
  } = useContext(Context);

  const history = useHistory();

  const [publishedID, setPublishedID] = useState({
    ID: '',
    touched: 0,
  });

  const [filterState, setFilterState] = useState(false);
  const toggleFilter = () => setFilterState(!filterState);

  const [totalCases, setTotalCases] = useState({
    biz_total: null,
    total: null,
  });
  const [searchFlag, setSearchFlag] = useState(null);

  const [modalIndividualModal, setIndividualModal] = useState(false);
  const toggleIndividualModal = () => setIndividualModal(!modalIndividualModal);
  const closeIndividualModal = (
    <button className="close" onClick={toggleIndividualModal}>
      <img
        src={require('../assets/images/arrow-left.svg')}
        className="icon"
        alt="arrow left icon"
      />
    </button>
  );
  const [individualErrorMessage, setIndividualErrorMessage] = useState('');

  const [modalEntityModal, setEntityModal] = useState(false);
  const toggleEntityModal = () => setEntityModal(!modalEntityModal);
  const closeEntityModal = (
    <button className="close" onClick={toggleEntityModal}>
      <img
        src={require('../assets/images/arrow-left.svg')}
        className="icon"
        alt="arrow left icon"
      />
    </button>
  );

  const [entityErrorMessage, setEntityErrorMessage] = useState('');
  const [cases, setCases] = useState([]);

  const [statusFilterText, setStatusFilterText] = useState('Status');
  const [caseTypeText, setCaseTypeText] = useState('Case Type');
  const [range, setRange] = useState(3);
  const [isUpdatedSort, setUpdatedSort] = useState({
    updatedSortBy: 'TotalActivity',
  });

  const [individualCaseData, setIndividualCaseData] = useState({
    customer_id: '',
    first_name: null,
    last_name: null,
    date_birth: null,
    nationality: null,
    country_address: null,
    case_type: '1',
    biz_id: null,
  });

  const [entityCaseData, setEntityCaseData] = useState({
    customer_id: null,
    company_name: null,
    country_incorporation: null,
    country_address: null,
    case_type: '2',
    biz_id: null,
  });

  // UPDATE PAGE INDEX
  const handlePageChange = (pageNumber) => {
    updatePageIndex(pageNumber);
    updateIsLoadingState(true);
  };

  // UPDATE PAGE SIZE
  function getSetValue(e) {
    updatePageSize(parseInt(e.target.value));
    updateIsLoadingState(true);
  }

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleIndividualCaseData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'customer_id') {
      if (value === '') {
        setIndividualErrorMessage('Please provide a valid Customer ID');
      } else {
        setIndividualErrorMessage('');
      }
    }

    setIndividualCaseData({
      ...individualCaseData,
      [name]: value,
    });
  };

  // CREATE INDIVIDUAL CASE
  const submitIndividualCase = () => {
    if (individualCaseData.customer_id === '') {
      setIndividualErrorMessage('Please provide a valid Customer ID');
    } else {
      updateIsLoadingState(true);
      axios
        .post(
          CASES_ENDPOINTS.create.individual,
          individualCaseData,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          setPublishedID({
            ID: individualCaseData.customer_id,
            touched: 1,
          });
          toggleIndividualModal();
          updateIsLoadingState(false);
          updateNewCase(!newCase);
        })
        .catch((err) => {
          console.log(err.response.data);
          if (err.response.data.code[0] !== RESPONSE_CODES.httpAuthErrCode) {
            err.response.data.message[0] ===
            'The record already exists or a specified resource is not found'
              ? setIndividualErrorMessage(
                  'A record with this Customer ID already exist.'
                )
              : setIndividualErrorMessage(err.response.data.message[0]);
          } else if (
            err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
          ) {
            history.push('/login');
          }
          updateIsLoadingState(false);
        });
    }
  };

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleEntityCaseData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'customer_id') {
      if (value === '') {
        setEntityErrorMessage('Please provide a valid Customer ID');
      } else {
        setEntityErrorMessage('');
      }
    }

    setEntityCaseData({
      ...entityCaseData,
      [name]: value,
    });
  };

  // CREATE ENTITY CASE
  const submitEntityCase = () => {
    if (entityCaseData.customer_id === '') {
      setEntityErrorMessage('Please provide a valid Customer ID');
    } else {
      updateIsLoadingState(true);
      axios
        .post(
          CASES_ENDPOINTS.create.entity,
          entityCaseData,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          setPublishedID({
            ID: entityCaseData.customer_id,
            touched: 1,
          });
          toggleEntityModal();
          updateIsLoadingState(false);
          updateNewCase(!newCase);
        })
        .catch((err) => {
          if (err.response.data.code[0] === RESPONSE_CODES.httpServerErrCode) {
            console.log('internal server error');
          } else if (
            err.response.data.code[0] === RESPONSE_CODES.httpBadRequest
          ) {
            err.response.data.message[0] ===
            'The record already exists or a specified resource is not found'
              ? setEntityErrorMessage(
                  'A record with this Customer ID already exist'
                )
              : setEntityErrorMessage(err.response.data.message[0]);
          } else if (
            err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
          ) {
            history.push('/login');
          }
        });
    }
  };

  const [countries, setCountries] = useState([]);

  // GET ALL COUNTRIES
  const fetchCountries = () => {
    axios
      .get(CASES_ENDPOINTS.countries, auth.getAuthConfig())
      .then(({ data }) => {
        const validator = response.validate(data.code[0], data.message[0]);
        if (validator) {
          setCountries(data.content);
        }
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // READ ALL CASES
  const fetchAllCases = () => {
    var endpoint =
      CASES_ENDPOINTS.read.all +
      'biz_id=' +
      auth.get('biz_id') +
      '&pagesize=' +
      pageSize +
      '&pageindex=' +
      (pageIndex - 1);

    if (filterCaseStatus !== null && filterCaseType === null) {
      endpoint += '&status_id=' + filterCaseStatus;
    } else if (filterCaseStatus === null && filterCaseType !== null) {
      endpoint += '&case_type_id=' + filterCaseType;
    } else if (filterCaseStatus !== null && filterCaseType !== null) {
      endpoint +=
        '&status_id=' + filterCaseStatus + '&case_type_id=' + filterCaseType;
    }

    endpoint += '&sortcol=' + sortBy + '&sortdir=' + sortDir;

    axios
      .get(endpoint, auth.getAuthConfig())
      .then(({ data }) => {
        console.log(data.content);
        const validator = response.validate(data.code[0], data.message[0]);
        if (validator) {
          setCases(data.content['items']);
          setSearchFlag(true);
          updatePageTotalItems(data.content['total'][0]);
          setTotalCases({
            biz_total: data.content['biz_total'][0],
            total: data.content['total'][0],
          });
          updateTotalPages(
            Math.ceil(data.content['total'][0].Total / pageSize)
          );
          updateIsLoadingState(false);
        } else {
          console.log('err', data);
        }
      })
      .catch((err) => {
        if (err.response.data.code && err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleSearchTerm = (e) => {
    updateSearchTerm(e.target.value);
  };

  const submitSearchTerm = (e) => {
    e.preventDefault();

    axios
      .get(
        CASES_ENDPOINTS.read.caseCustomer + 'customer_id=' + searchTerm,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        setCases(data.content);
        setSearchFlag(true);
        console.log(data);
      })
      .catch((err) => {
        if (err.response && err.response.data.code[0] === RESPONSE_CODES.httpBadRequest) {
          // SHOW ILLUSTRATION HERE
          setCases([]);
          setSearchFlag(false);
          updatePageTotalItems(0);
          updateTotalPages(0);
        } else if (
          err.response && err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
        ) {
          history.push('/login');
        }
      });
  };

  useEffect(() => {
    setUpdatedSort({ updatedSortBy: sortBy });
    fetchAllCases();
    fetchCountries();
    setRange(3);
  }, [
    pageIndex,
    pageSize,
    isLoading,
    sortBy,
    sortDir,
    filterCaseType,
    filterCaseStatus,
  ]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIndividualCaseData({
        ...individualCaseData,
        biz_id: localStorage.getItem('biz_id'),
        case_id: '',
        customer_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        company_name: '',
        country_incorporation: '',
        country_address: '',
        date_birth: '',
        nationality: '',
        case_risk: 0,
        sanction: 0,
        adverse_media: 0,
        pep: 0,
        comments: '',
        status: 1,
        date_incoportation: '',
      });

      setEntityCaseData({
        ...entityCaseData,
        biz_id: localStorage.getItem('biz_id'),
        case_id: '',
        customer_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        company_name: '',
        country_incorporation: '',
        country_address: '',
        date_birth: '',
        nationality: '',
        case_risk: 0,
        sanction: 0,
        adverse_media: 0,
        pep: 0,
        comments: '',
        status: 1,
        date_incoportation: '',
      });
    }
    updatePageIndex(1);
  }, []);

  return (
    <div className="container">
      <div className="accordion-table plain">
        <ul className="table-header">
          <li className="table-title">
            <img src={Menu} className="icon-menu" alt="ospree-menu" />
            <p>Case Table</p>
          </li>
          <li className="table-options">
            <button className="btn btn-icon">
              <img
                src={Filter}
                onClick={toggleFilter}
                className="icon"
                alt="ospree-filter"
              />
            </button>
            <UploadWindow />
            <div className="dropdown-holder create-case">
              <UncontrolledDropdown>
                <DropdownToggle className="drop-blue" caret>
                  <img
                    src={require('../assets/images/plus-white.svg')}
                    className="icon"
                    alt="plus icon"
                  />
                  Create Case
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={toggleIndividualModal}>
                    <img
                      src={require('../assets/images/users.svg')}
                      className="icon"
                      alt="users icon"
                    />
                    Individual Case
                  </DropdownItem>
                  <DropdownItem onClick={toggleEntityModal}>
                    <img
                      src={require('../assets/images/briefcase.svg')}
                      className="icon"
                      alt="briefcase icon"
                    />
                    Entity Case
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </li>
        </ul>
        <div
          className={classnames('table-data filter-options', {
            active: filterState === true,
          })}
        >
          <Form className="search-form" onSubmit={submitSearchTerm}>
            <FormGroup
            // className={classnames('customer-id-input', {
            //   invalid: searchErrorMessage !== '',
            // })}
            >
              <InputGroup>
                <Input
                  onKeyPress={handleSearchTerm}
                  onChange={handleSearchTerm}
                  placeholder="Search"
                  className="search-input"
                />

                <button type="submit" className="btn-search">
                  <img
                    src={require('../assets/images/search.svg')}
                    alt="search icon"
                  />
                </button>
              </InputGroup>
              {/* {searchErrorMessage && (
                <p className="error-message">{searchErrorMessage}</p>
              )} */}
            </FormGroup>
          </Form>
          <UncontrolledDropdown>
            <DropdownToggle caret>{caseTypeText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setCaseTypeText('All');
                  updateFilterCaseType(null);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                All
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setCaseTypeText('Legal Person');
                  updateFilterCaseType(2);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                Legal Person
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setCaseTypeText('Natural Person');
                  updateFilterCaseType(1);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                Natural Person
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown>
            <DropdownToggle caret>{statusFilterText}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setStatusFilterText('All');
                  updateFilterCaseStatus(null);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                All
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setStatusFilterText('Inactive');
                  updateFilterCaseStatus(0);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                Inactive
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setStatusFilterText('Active');
                  updateFilterCaseStatus(1);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                Active
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setStatusFilterText('On Hold');
                  updateFilterCaseStatus(2);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                On Hold
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setStatusFilterText('Dormant');
                  updateFilterCaseStatus(3);
                  updateNewFilter();
                  updateIsLoadingState(true);
                }}
              >
                Dormant
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <ul className="table-header plain">
          <li className="table-row-title">Case Type</li>
          <li className="table-row-title">Customer ID</li>
          <li className="table-row-title">Status</li>
          <li className="table-row-title">
            <div className="with-sub">
              <span>Transaction Monitoring</span>
              <span className="subheading">(Last 30 days)</span>
            </div>
          </li>
          <li className="table-row-title">
            <div className="with-sub">
              <span>Whitelisting Activity</span>
              <span className="subheading">(Last 30 days)</span>
            </div>
          </li>
        </ul>

        {totalCases.biz_total !== 0 && (
          <>
            {cases.map((caseItem, i) => {
              return <TableRow caseItem={caseItem} key={i} />;
            })}
          </>
        )}

        {totalCases.biz_total === 0 && (
          <Illustration
            image={require('../assets/images/icon-create-case.svg')}
            heading="Create your first case profile"
            description={
              <>
                Use the <span>+ Create Case</span> button to set up a new
                customer profile
              </>
            }
          />
        )}

        {totalCases.total === 0 && totalCases.biz_total !== 0 && (
          <Illustration
            image={require('../assets/images/icon-no-result.svg')}
            heading="No search result"
            description={
              <>
                Please, try a different combination of <span>filters</span>
              </>
            }
          />
        )}

        {searchFlag === false && (
          <Illustration
            image={require('../assets/images/icon-no-result.svg')}
            heading="No search result"
            description={
              <>
                Please, try a different combination of <span>filters</span>
              </>
            }
          />
        )}
      </div>

      <div className="pagination-wrapper">
        <Pagination
          prevPageText={
            <img
              src={require('../assets/images/chevron-left.svg')}
              className="icon icon-grid"
              alt="left chevron"
            />
          }
          nextPageText={
            <img
              src={require('../assets/images/chevron-right.svg')}
              className="icon icon-grid"
              alt="right chevron"
            />
          }
          activePage={pageIndex}
          itemsCountPerPage={parseInt(pageSize)}
          totalItemsCount={pageTotalItems}
          pageRangeDisplayed={range}
          onChange={handlePageChange}
        />

        <div className="select-holder">
          <select id="item-length" onChange={getSetValue} value={pageSize}>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="30">30 per page</option>
            <option value="40">40 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
      </div>

      <Modal
        isOpen={modalIndividualModal}
        toggle={toggleIndividualModal}
        backdrop={true}
      >
        <ModalHeader
          toggle={toggleIndividualModal}
          close={closeIndividualModal}
        >
          Create Individual Case
        </ModalHeader>
        <ModalBody>
          <Form>
            <h4 className="t-h4">
              What is the Customer ID of the case you want to create?
            </h4>
            <FormGroup
              className={classnames('customer-id-input', {
                invalid: individualErrorMessage !== '',
              })}
            >
              <Input
                type="text"
                name="customer_id"
                id="id"
                placeholder="Customer ID e.g. ID0001"
                onChange={handleIndividualCaseData}
              />
              <p className="error-message">{individualErrorMessage}</p>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="first_name"
                id="first-name"
                placeholder="First Name"
                onChange={handleIndividualCaseData}
              />
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="last_name"
                id="last-name"
                placeholder="Last Name"
                onChange={handleIndividualCaseData}
              />
            </FormGroup>
            <FormGroup>
              <ReactDatez
                allowPast
                allowFuture={false}
                dateFormat={Dates_Calendar.dateFormat}
                placeholder={`Date of Birth ${Dates_Calendar.dateFormat}`}
                value={individualCaseData.date_birth}
                handleChange={(value) =>
                  setIndividualCaseData({
                    ...individualCaseData,
                    date_birth: moment(value).format(Dates_Calendar.dateFormat),
                  })
                }
                name="dateInput"
              />
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="nationality"
                id="select"
                onChange={handleIndividualCaseData}
              >
                <option selected disabled hidden>
                  Nationality
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="country_address"
                id="select"
                onChange={handleIndividualCaseData}
              >
                <option selected disabled hidden>
                  Country Address
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="create-popup-footer">
          <button
            className="btn-custom btn-blue btn-hover-violet"
            onClick={() => submitIndividualCase()}
          >
            Create Case
          </button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={modalEntityModal}
        backdrop={true}
        toggle={toggleEntityModal}
      >
        <ModalHeader toggle={toggleEntityModal} close={closeEntityModal}>
          Create Entity Case
        </ModalHeader>
        <ModalBody>
          <Form>
            <h4 className="t-h4">
              What is the Customer ID of the case you want to create?
            </h4>
            <FormGroup
              className={classnames('customer-id-input', {
                invalid: entityErrorMessage !== '',
              })}
            >
              <Input
                type="text"
                name="customer_id"
                id="id"
                placeholder="Customer ID e.g. ID0001"
                onChange={handleEntityCaseData}
              />
              <p className="error-message">{entityErrorMessage}</p>
            </FormGroup>
            <FormGroup>
              <Input
                type="text"
                name="company_name"
                id="company-name"
                placeholder="Company Name"
                onChange={handleEntityCaseData}
              />
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="country_incorporation"
                id="select"
                onChange={handleEntityCaseData}
              >
                <option selected disabled hidden>
                  Incoporation Country
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>

            <FormGroup className="select-holder">
              <Input
                type="select"
                name="country_address"
                id="select"
                onChange={handleEntityCaseData}
              >
                <option selected disabled hidden>
                  Company Address
                </option>
                {countries.length !== 0 &&
                  countries.map((country, i) => {
                    return (
                      <option key={i} value={country.Code}>
                        {country.Description}
                      </option>
                    );
                  })}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="create-popup-footer">
          <button
            className="btn-custom btn-blue btn-hover-violet"
            onClick={submitEntityCase}
          >
            Create Case
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withRouter(Table);
