import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';

import Header from '../components/header';
import AccordionTable from '../components/AccordionTable';

import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const CaseAddresses = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    dateRange,
    sortBy,
    sortDir,
    removing,
    assigning,
    searchState,
    pageIndex,
    pageSize,
    isLoading,
    updateIsLoadingState,
    updatePageTotalItems,
    updateTotalPages,
    updateActiveModule,
    updateDataLoaded,
    updatePageIndex,
    updateSortBy,
    updateSortDir,
    updateParentImportModule,
  } = useContext(Context);

  const history = useHistory();

  const [totalAddresses, setTotalAddresses] = useState(null);
  const [type, setType] = useState(null);
  const [status, setStatus] = useState(null);
  const [caseID, setCaseID] = useState(null);
  const [whitelistData, setWhitelistData] = useState({
    Module: 'Whitelisting',
    TableRow1Title: 'Address',
    TableRow2Title: 'Asset',
    TableRow3Title: 'Balance',
    TableRow4Title: 'Risk Score',
    TableRow5Title: 'Timestamp',
    items: [],
    total: [],
  });

  // api response checker
  const responseChecker = (code, message) => {
    if (code === 200) {
      return true;
    } else {
      return code + '=>' + message;
    }
  };

  // ACCESS AVAILABLE URL PARAMETER
  const {
    match: { params },
  } = props;

  // ACCESS CASE ADDRESSES
  const readCaseAddresses = () => {
    axios
      .get(
        CASES_ENDPOINTS.read.caseCustomer + 'customer_id=' + params.customerId,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        setTotalAddresses(data.content[0].AddressCount);
        setStatus(data.content[0].Status_ID);
        setType(data.content[0].Case_Type_ID);
        setCaseID(data.content[0].Case_ID);
        if (typeof window !== `undefined`) {
          localStorage.setItem('active-case', data.content[0].Case_ID);
        }

        sortBy !== 'AddressCount' &&
          sortBy !== 'TotalActivity' &&
          axios
            .get(
              CASES_ENDPOINTS.read.addressCase +
                'case_id=' +
                data.content[0].Case_ID +
                '&end_range=' +
                dateRange.endDate +
                '&start_range=' +
                dateRange.startDate +
                '&sortby=' +
                sortBy +
                '&sortdir=' +
                sortDir +
                '&pageindex=' +
                (pageIndex - 1) +
                '&pagesize=' +
                pageSize,
              auth.getAuthConfig()
            )
            .then(({ data }) => {
              const validator = responseChecker(data.code[0], data.message[0]);

              if (validator) {
                setWhitelistData({
                  Module: 'Whitelisting',
                  TableRow1Title: 'Address',
                  TableRow2Title: 'Asset',
                  TableRow3Title: 'Balance',
                  TableRow4Title: 'Risk Score',
                  TableRow5Title: 'Timestamp',
                  items: data.content.items,
                  total: data.content.total[0],
                  biz_total: data.content.biz_total[0],
                  illustrationHeading: 'Search your first address',
                  illustrationDesc: (
                    <>
                      Use the <span>search bar</span> at the top of the page to
                      conduct an address search query
                    </>
                  ),
                });

                updateDataLoaded();
                updatePageTotalItems(data.content.total[0]);
                updateTotalPages(Math.ceil(data.content.total[0] / pageSize));
                updateIsLoadingState(false);
              } else {
                console.log(validator);
              }
            })
            .catch((err) => {
              console.log(err);
              if (err.response.data.code[0] === RESPONSE_CODES.httpBadRequest) {
                setWhitelistData({
                  Module: 'Whitelisting',
                  TableRow1Title: 'Address',
                  TableRow2Title: 'Asset',
                  TableRow3Title: 'Balance',
                  TableRow4Title: 'Risk Score',
                  TableRow5Title: 'Timestamp',
                  items: [],
                  total: 0,
                  biz_total: 0,
                  illustrationHeading: 'Search your first address',
                  illustrationDesc: (
                    <>
                      Use the <span>search bar</span> at the top of the
                      whitelisting module
                    </>
                  ),
                });
                updateDataLoaded();
                updatePageTotalItems(0);
                updateTotalPages(0);
                updateIsLoadingState(false);
              }
            });
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // RETURN STATUS'S STRING VALUE DEPENDS ON STATUS' INTEGER VALUE FROM THE API
  const statusChecker = (status) => {
    if (status === 0) {
      return 'Inactive';
    } else if (status === 1) {
      return 'Active';
    } else if (status === 2) {
      return 'On Hold';
    } else if (status === 3) {
      return 'Dormant';
    }
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      localStorage.setItem('importModule', 'addresses');
    }
    updateActiveModule('Whitelisting');
    updateParentImportModule('Whitelisting');
    updateSortBy('CreateTimestamp');
    updatePageIndex(1);
    updateSortDir('DESC');
  }, []);

  useEffect(() => {
    readCaseAddresses();
  }, [
    dateRange,
    searchState,
    sortBy,
    sortDir,
    removing,
    assigning,
    isLoading,
    pageIndex,
    pageSize,
  ]);

  return (
    <div className="home case-management">
      <Header customerId={params.customerId} />
      <div className="content">
        <div className="container">
          <div className="heading-table">
            <div className="heading-table-col">
              <p>
                ID: <span> {params.customerId} </span>
              </p>
              <p>
                Total Addresses: <span>{totalAddresses} </span>
              </p>
            </div>
            <div className="heading-table-col">
              <p>
                Status: <span> {statusChecker(status)}</span>
              </p>
              <p>
                Type: <span> {type === 1 ? 'Individual' : 'Entity'} </span>
              </p>
            </div>
          </div>
          <AccordionTable content={whitelistData} caseID={caseID} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(CaseAddresses);
