// EXPECTS UNIFORM OBJECT STRUCTURE
export const arrayOfObjects = (array, innerArrayField, field) => {
    let maxValue = 0
    
    const getMaxValueRecursively = (array, innerArrayField, field) => {
      if (array && array.length) {
        array.map(item => {
          if (item[field]) {
            if (item[field] > maxValue) maxValue = item[field]
          }
          getMaxValueRecursively(item[innerArrayField], innerArrayField, field)
        })
      }
    }

    getMaxValueRecursively(array, innerArrayField, field)

    return maxValue
}