import React, { useState } from 'react';
import axios from 'axios';

import auth from '../helpers/auth';
import response from '../helpers/responseChecker';
import { External_URLs } from '../helpers/externalUrls';
import { LOGIN_ENDPOINT } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const RecoverPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userCredentials, setUserCredentials] = useState({
    email: '',
  });

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleData = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserCredentials({
      ...userCredentials,
      [name]: value.replace(/\s/g, ''),
    });
  };

  // SEND STATE VARIABLES' VALUES TO THE API
  const handleSubmit = (e) => {
    e.preventDefault();
 
  };

  return (
    <>
      <header className="recover-password-header">
        <div className="container recover-password-container">
          <a href={External_URLs.mainSite}>
            <img
              src={require('../assets/images/brand-logo-white.png')}
              className="recover-password-logo"
              alt="recover-password-logo"
            />
          </a>
        </div>
      </header>

      <div className="recover-password password">
        <div className="container recover-password-container">
          <div className="recover-password-wrapper">
            <div className="form-wrapper">
              <div className="form-content">
                <h1 className="recover-password-title">Recover Password</h1>
                <p className="recover-password-description">Please enter recovery email below.</p>
                <form className="form" onSubmit={handleSubmit}>
                  <label htmlFor="email" className="subtitle">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    onChange={handleData}
                  />
                  <button
                    type="submit"
                    className="btn-custom btn-blue btn-hover-violet"
                  >
                    {loading ? 'Loading...' : 'Email Recovery Link'}
                  </button>
                  <p className="error-message">{errorMsg}</p>
                </form>
                <p className="footer-form-text">
                </p>
              </div>
              <a
                href={External_URLs.privacyPolicy}
                className="privacy-terms-link"
              >
                Privacy Policy • Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecoverPassword;
