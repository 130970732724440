import React, { useState, Fragment, useEffect, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from '../components/header';
import classnames from 'classnames';
import moment from 'moment';
import { ReactDatez } from 'react-datez';
import 'react-datez/dist/css/react-datez.css';
import {
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { Dates_Calendar } from '../helpers/datesCalendar';
import { CASES_ENDPOINTS } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const CaseDetails = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    countries,
    fetchCountries,
    isProfileUpdated,
    updateIsLoadingState,
    updateIsProfileUpdated,
    closeDropZone,
  } = useContext(Context);

  const history = useHistory();

  const [activeTab, setActiveTab] = useState('1');
  const [customerData, setCustomerData] = useState({});
  const [customerAllData, setCustomerAllData] = useState({});

  // ACCESS AVAILABLE URL PARAMETER
  const {
    match: { params },
  } = props;

  // SET ACTIVE TAB
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // READ CASE CUSTOMER DATA
  const getCustomerData = () => {
    axios
      .get(
        CASES_ENDPOINTS.read.caseCustomer + 'customer_id=' + params.customerId,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        console.log(data.content[0]);
        setCustomerAllData(data.content[0]);
        setCustomerData({
          business_id: data.content[0].Business_ID,
          business_name: data.content[0].Business_Name,
          customer_id: data.content[0].Customer_ID,
          case_id: data.content[0].Case_ID,
          first_name: data.content[0].First_Name,
          middle_name: data.content[0].Middle_Name,
          last_name: data.content[0].Last_Name,
          date_birth: data.content[0].Date_Birth,
          company_name: data.content[0].Company_Name,
          date_incorporation: data.content[0].Date_Incorporation,
          comments: data.content[0].Comments,
          case_since: data.content[0].Case_Since,
          last_modified: data.content[0].Last_Modified,
          nationality: data.content[0].Nationality,
          nationalityText: data.content[0].Nationality_Description,
          country_address: data.content[0].Country_Address,
          country_incorporation: data.content[0].Country_Incorporation,
          case_type_id: data.content[0].Case_Type_ID,          
          case_type: data.content[0].Case_Type,
          status: data.content[0].Status_ID,
          statusText: data.content[0].Case_Status,
          pep: data.content[0].PEP_ID,
          pepText: data.content[0].PEP,
          adverse_media: data.content[0].Adverse_Media_ID,
          adverseMediaText: data.content[0].Adverse_Media,
          case_risk: data.content[0].Risk_ID,
          caseRiskText: data.content[0].Case_Risk,
          
        });
        updateIsProfileUpdated(false);
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };

  // SAVE CASE CUSTOMER DATA
  const saveCustomerData = (e) => {
    e.preventDefault();
    updateIsLoadingState(true);

    if (
      customerData.company_name === '' ||
      !/\S/.test(customerData.company_name)
    ) {
      customerData.company_name = customerAllData.Company_Name;
    }

    if (customerData.first_name === '' || !/\S/.test(customerData.first_name)) {
      customerData.first_name = customerAllData.First_Name;
    }

    if (
      customerData.middle_name === '' ||
      !/\S/.test(customerData.middle_name)
    ) {
      customerData.middle_name = customerAllData.Middle_Name;
    }

    if (customerData.last_name === '' || !/\S/.test(customerData.last_name)) {
      customerData.last_name = customerAllData.Last_Name;
    }

    if (customerData.comments === '' || !/\S/.test(customerData.comments)) {
      customerData.comments = customerAllData.Comments;
    }



    if (customerData.case_risk === customerAllData.Case_Risk) {
      if (customerData.case_risk === 'Low') {
        customerData.case_risk = 1;
      } else if (customerData.case_risk ==='Medium') {
        customerData.case_risk = 2;
      } else if (customerData.case_risk ==='High') {
        customerData.case_risk = 3;
      } else {
        customerData.case_risk = 0;
      }
    }

    if (customerData.adverse_media === customerAllData.Adverse_Media) {
      if (customerData.adverse_media === 'No') {
        customerData.adverse_media = 1;
      } else if (customerData.adverse_media === 'Yes') {
        customerData.adverse_media = 2;
      } else {
        customerData.adverse_media = 0;
      }
    }

    if (customerData.pep === customerAllData.PEP) {
      if (customerData.pep === 'No') {
        customerData.pep = 1;
      } else if (customerData.pep === 'Yes') {
        customerData.pep = 2;
      } else {
        customerData.pep = 0;
      }
    }

    // console.log(customerData.case_risk);

    axios
      .put(
        CASES_ENDPOINTS.update,
        customerData,
        auth.getAuthConfig()
      )
      .then(({ data }) => {
        setCustomerData(customerData);
        updateIsLoadingState(false);
        updateIsProfileUpdated(true);
      })
      .catch((err) => {
        if (err.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode) {
          history.push('/login');
        }
      });
  };
  
  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleCustomerData = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === 'status') {
      value = parseInt(value);
    }

    if (name === 'pep') {
      value = parseInt(value);
    }

    if (name === 'adverse_media') {
      value = parseInt(value);
    }

    if (name === 'case_risk') {
      value = parseInt(value);
    }

    setCustomerData({
      ...customerData,
      [name]: value,
    });
  };

  useEffect(() => {
    getCustomerData();
  }, [isProfileUpdated]);

  useEffect(() => {
    fetchCountries();
    if (typeof window !== `undefined`) {
      localStorage.setItem('importModule', 'details');
      closeDropZone();
    }
  }, []);

  return (
    <main className="case-management">
      <Header customerId={params.customerId} />
      <div className="case-management-content">
        <div className="form-holder">
          <div className="container">
            <div className="form-wrapper">
              <div className="form-header">
                <p>Case Details</p>
              </div>
              <div className="form-body">
                <div className="form-body-col">
                  <div className="button-wrapper">
                    <button
                      className={classnames('form-btn', {
                        active: activeTab === '1',
                      })}
                      onClick={() => {
                        toggle('1');
                      }}
                    >
                      Profile Information
                    </button>
                    <button
                      className={classnames('form-btn', {
                        active: activeTab === '2',
                      })}
                      onClick={() => {
                        toggle('2');
                      }}
                    >
                      KYC Overview
                    </button>
                  </div>
                </div>
                <div className="form-body-col">
                  <Form onSubmit={saveCustomerData}>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <p className="form-body-heading">Profile Information</p>
                        <div className="form-col-holder">
                          <div className="form-col-wrapper">
                            <FormGroup>
                              <Label for="customer_id">Customer ID</Label>
                              <Input
                                type="text"
                                name="customer_id"
                                id="customerId"
                                value={customerData.customer_id || ''}
                                placeholder="Customer ID"
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="case_type">Case Type</Label>
                              <Input
                                type="text"
                                name="case_type"
                                id="caseType"
                                value={customerData.case_type || ''}
                                placeholder="Case Type"
                                disabled
                              />
                            </FormGroup>
                          </div>

                          {customerData.case_type === 'Natural Person' && (
                            <Fragment>
                              <div className="form-col-wrapper">
                                <FormGroup>
                                  <Label for="first_name">First Name</Label>
                                  <Input
                                    type="text"
                                    name="first_name"
                                    id="firstName"
                                    value={customerData.first_name || ''}
                                    onChange={handleCustomerData}
                                    placeholder="First Name"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="middle_name">Middle Name</Label>
                                  <Input
                                    type="text"
                                    name="middle_name"
                                    id="middleName"
                                    value={customerData.middle_name || ''}
                                    onChange={handleCustomerData}
                                    placeholder="Middle Name"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="last_name">Last Name</Label>
                                  <Input
                                    type="text"
                                    name="last_name"
                                    id="lastName"
                                    value={customerData.last_name || ''}
                                    onChange={handleCustomerData}
                                    placeholder="Last Name"
                                  />
                                </FormGroup>
                              </div>

                              <div className="form-col-wrapper">
                                <FormGroup>
                                  <Label for="date_birth">Date of Birth</Label>
                                  <ReactDatez
                                    allowPast
                                    allowFuture={false}
                                    dateFormat={Dates_Calendar.dateFormat}
                                    placeholder={Dates_Calendar.dateFormat}
                                    value={customerData.date_birth || null}
                                    handleChange={(value) =>
                                      setCustomerData({
                                        ...customerData,
                                        date_birth: moment(value).format(
                                          Dates_Calendar.dateFormat
                                        ),
                                      })
                                    }
                                    name="date_birth"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="nationality">Nationality</Label>
                                  <Input
                                    type="select"
                                    name="nationality"
                                    id="nationality"
                                    value={customerData.nationality}
                                    onChange={handleCustomerData}
                                  >
                                    <option>
                                      Choose Country
                                    </option>
                                    {countries.length !== 0 &&
                                      countries.map((country, i) => {
                                        return (
                                          <option key={i} value={country.Code}>
                                            {country.Description}
                                          </option>
                                        );
                                    })}
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label for="country_address">
                                    Country of Domicile
                                  </Label>
                                  <Input
                                    type="select"
                                    name="country_address"
                                    id="countryAddress"
                                    value={customerData.country_address}
                                    onChange={handleCustomerData}
                                  >
                                    <option>
                                      Choose Country
                                    </option>
                                    {countries.length !== 0 &&
                                      countries.map((country, i) => {
                                        return (
                                          <option key={i} value={country.Code}>
                                            {country.Description}
                                          </option>
                                       );
                                    })}

                                  </Input>
                                </FormGroup>
                              </div>
                            </Fragment>
                          )}

                          {customerData.case_type === 'Legal Person' && (
                            <Fragment>
                              <div className="form-col-wrapper">
                                <FormGroup className="company-name">
                                  <Label for="company_name">Company Name</Label>
                                  <Input
                                    type="text"
                                    name="company_name"
                                    id="companyName"
                                    value={customerData.company_name}
                                    onChange={handleCustomerData}
                                    placeholder="Company Name"
                                  />
                                </FormGroup>
                              </div>

                              <div className="form-col-wrapper">
                                <FormGroup>
                                  <Label for="date_incorporation">
                                    Date of Incorporation
                                  </Label>
                                  <ReactDatez
                                    allowPast
                                    allowFuture={false}
                                    dateFormat={Dates_Calendar.dateFormat}
                                    placeholder={Dates_Calendar.dateFormat}
                                    value={customerData.date_incorporation || null}
                                    handleChange={(value) =>
                                      setCustomerData({
                                        ...customerData,
                                        date_incorporation: moment(value).format(
                                          Dates_Calendar.dateFormat
                                        ),
                                      })
                                    }
                                    name="date_incorporation"
                                  />
                                </FormGroup>
                                <FormGroup>
                                  <Label for="country_incorporation">
                                    Country of Incorporation
                                  </Label>
                                  <Input
                                    type="select"
                                    name="country_incorporation"
                                    id="countryIncorporation"
                                    value={customerData.country_incorporation}
                                    onChange={handleCustomerData}
                                  >
                                    <option> 
                                      Choose Country
                                    </option>
                                    {countries.length !== 0 &&
                                      countries.map((country, i) => {
                                        return (
                                          <option key={i} value={country.Code}>
                                            {country.Description}
                                          </option>
                                        );
                                      })}
                                  </Input>
                                </FormGroup>
                                <FormGroup>
                                  <Label for="country_address">
                                    Country of Domicile
                                  </Label>
                                  <Input
                                    type="select"
                                    name="country_address"
                                    id="countryAddress"
                                    value={customerData.country_address}
                                    onChange={handleCustomerData}
                                  >
                                    <option>
                                      Choose Country
                                    </option>
                                    {countries.length !== 0 &&
                                      countries.map((country, i) => {
                                        return (
                                          <option key={i} value={country.Code}>
                                            {country.Description}
                                          </option>
                                        );
                                      })}
                                  </Input>
                                </FormGroup>
                              </div>
                            </Fragment>
                          )}

                          <div className="form-col-wrapper">
                            <FormGroup>
                              <Label for="case_since">Case Since</Label>
                              <Input
                                type="text"
                                name="case_since"
                                id="caseSince"
                                value={customerData.case_since || ''}
                                placeholder={Dates_Calendar.dateFormat}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="last_modified">Last Modified</Label>
                              <Input
                                type="text"
                                name="last_modified"
                                id="lastModified"
                                value={customerData.last_modified || ''}
                                placeholder={Dates_Calendar.dateFormat}
                                disabled
                              />
                            </FormGroup>
                            <FormGroup className="filter-options">
                              <Label for="status">Status</Label>
                              <UncontrolledDropdown>
                                <DropdownToggle caret>
                                  {customerData.statusText || 'Status'}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        status: 0,
                                        statusText: 'Inactive',
                                      });
                                    }}
                                  >
                                    Inactive
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        status: 1,
                                        statusText: 'Active',
                                      });
                                    }}
                                  >
                                    Active
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        status: 2,
                                        statusText: 'On Hold',
                                      });
                                    }}
                                  >
                                    On Hold
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        status: 3,
                                        statusText: 'Dormant',
                                      });
                                    }}
                                  >
                                    Dormant
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </FormGroup>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId="2">
                        <p className="form-body-heading">
                          Know Your Customer (KYC) Overview
                        </p>
                        <div className="form-col-holder">
                          <div className="form-col-wrapper">
                            <FormGroup className="filter-options">
                              <Label for="cases_risk">Cases Risk</Label>
                              <UncontrolledDropdown>
                                <DropdownToggle caret>
                                  {Number.isInteger(customerData.case_risk)
                                    ? customerData.caseRiskText
                                    : customerData.case_risk}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        case_risk: 0,
                                        caseRiskText: 'Not Available',
                                      });
                                    }}
                                  >
                                    Not Available
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        case_risk: 1,
                                        caseRiskText: 'Low',
                                      });
                                    }}
                                  >
                                    Low
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        case_risk: 2,
                                        caseRiskText: 'Medium',
                                      });
                                    }}
                                  >
                                    Medium
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        case_risk: 3,
                                        caseRiskText: 'High',
                                      });
                                    }}
                                  >
                                    High
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </FormGroup>
                            <FormGroup className="filter-options">
                              <Label for="adverse_media">Adverse Media</Label>
                              <UncontrolledDropdown>
                                <DropdownToggle caret>
                                  {Number.isInteger(customerData.adverse_media)
                                    ? customerData.adverseMediaText
                                    : customerData.adverse_media}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        adverse_media: 0,
                                        adverseMediaText: 'Not Available',
                                      });
                                    }}
                                  >
                                    Not Available
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        adverse_media: 1,
                                        adverseMediaText: 'No',
                                      });
                                    }}
                                  >
                                    No
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        adverse_media: 2,
                                        adverseMediaText: 'Yes',
                                      });
                                    }}
                                  >
                                    Yes
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </FormGroup>
                            <FormGroup className="filter-options">
                              <Label for="pep">Politically Exposed Person</Label>
                              <UncontrolledDropdown>
                                <DropdownToggle caret>
                                  {Number.isInteger(customerData.pep)
                                    ? customerData.pepText
                                    : customerData.pep}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        pep: 0,
                                        pepText: 'Not Available',
                                      });
                                    }}
                                  >
                                    Not Available
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        pep: 1,
                                        pepText: 'No',
                                      });
                                    }}
                                  >
                                    No
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() => {
                                      setCustomerData({
                                        ...customerData,
                                        pep: 2,
                                        pepText: 'Yes',
                                      });
                                    }}
                                  >
                                    Yes
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </FormGroup>
                          </div>
                          <div className="form-col-wrapper">
                            <FormGroup>
                              <Label for="comments">Observations</Label>
                              <Input
                                type="textarea"
                                name="comments"
                                id="comments"
                                value={customerData.comments}
                                onChange={handleCustomerData}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </TabPane>
                    </TabContent>
                    <div className="form-footer">
                      <div className="button-wrapper">
                        <button className="form-btn">Save</button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default withRouter(CaseDetails);
