import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Accordion } from 'react-accessible-accordion';
import classnames from 'classnames';

//components
import SingleAccordionTable from './SingleAccordionTable';
import Pagination from 'react-js-pagination';
import { Context } from '../contexts/Context';

import Menu from '../assets/images/menu.svg';
import Filter from '../assets/images/filter.svg';
import CustomDateRangePicker from './CustomDateRangePicker';
import UploadOption from './UploadOption';
import Illustration from './Illustration';

const AccordionTable = ({ content, caseID }) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    sortBy,
    sortDir,
    updateSortBy,
    updateSortDir,
    searchState,
    pageIndex,
    pageTotalItems,
    pageSize,
    activeModule,
    searchFlag,
    updatePageSize,
    updatePageIndex,
    updateIsLoadingState,
    updateSearchFlag,
  } = useContext(Context);

  const [filterState, setFilterState] = useState(false);
  const [range, setRange] = useState(0);
  const [clickTimeout, setClickTimeout] = useState(null);
  const [isUpdatedSort, setUpdatedSort] = useState({
    updatedSortBy: 'CreateTimestamp',
  });
  const toggleFilter = useCallback(() => {
    setFilterState(!filterState);
  }, [filterState]);

  // UPDATE PAGE INDEX
  const handlePageChange = (pageNumber) => {
    updatePageIndex(pageNumber);
    updateIsLoadingState(true);
  };

  // HANDLE SINGLE AND DOUBLE CLICK EVENTS TO CAPTURE SORT SETTINGS
  const handleClicks = (newSort) => {
    updateIsLoadingState(true);
    if (clickTimeout !== null) {
      sortDir === 'DESC' ? updateSortDir('ASC') : updateSortDir('DESC');
      newSort !== sortBy ? updateSortBy(newSort) : console.log('x');
      clearTimeout(clickTimeout);
      setClickTimeout(null);
    } else {
      setClickTimeout(
        setTimeout(() => {
          newSort !== sortBy ? updateSortBy(newSort) : console.log('x');
          clearTimeout(clickTimeout);
          setClickTimeout(null);
        }, 2000)
      );

      setUpdatedSort({ updatedSortBy: newSort });
    }
  };

  // UPDATE PAGE SIZE
  function getSetValue(e) {
    updatePageSize(parseInt(e.target.value));
    updateIsLoadingState(true);
  }

  useEffect(() => {
    setRange(3);
    setUpdatedSort({ updatedSortBy: sortBy });
    updateSearchFlag(true);
  }, [searchState, sortBy, sortDir]);

  let sortByValues = [];

  // SET SORT VALUES DEPENDING ON WHAT MODULE IS THE USER IN
  if (activeModule === 'Monitoring') {
    sortByValues = [
      'Hash',
      'Asset',
      'Input_USD',
      'Transaction_Risk_Score',
      'CreateTimestamp',
    ];
  } else if (activeModule === 'Whitelisting') {
    sortByValues = [
      'Address',
      'Asset',
      'Balance',
      'Address_Risk_Score',
      'CreateTimestamp',
    ];
  }

  return (
    <>
      <Accordion className="accordion-table" allowZeroExpanded>
        <ul className="table-header">
          <li className="table-title">
            <img src={Menu} className="icon-menu" alt="ospree-menu" />
            <p>
              {activeModule === 'Monitoring'
                ? 'Transactions Table'
                : activeModule === 'Whitelisting'
                ? 'Addresses Table'
                : ''}
            </p>
          </li>
          <li className="table-options">
            <button className="btn btn-icon">
              <img
                src={Filter}
                onClick={toggleFilter}
                className="icon"
                alt="ospree-filter"
              />
            </button>
            <UploadOption caseID={caseID !== undefined && caseID} />
          </li>
        </ul>
        <div
          className={classnames(
            'table-data filter-options date-picker-holder',
            {
              collapse: filterState === true,
            }
          )}
        >
          <CustomDateRangePicker />
        </div>

        <ul className="table-header">
          <li
            className="table-row title"
            onClick={() => {
              handleClicks(sortByValues[0]);
            }}
          >
            {content.TableRow1Title}

            <span
              className={classnames('arrowSort', {
                active:
                  isUpdatedSort.updatedSortBy === sortByValues[0] &&
                  sortDir === 'ASC',
                visible: isUpdatedSort.updatedSortBy === sortByValues[0],
              })}
            />
          </li>

          <li
            className="table-row title"
            onClick={() => {
              handleClicks(sortByValues[1]);
            }}
          >
            {content.TableRow2Title}

            <span
              className={classnames('arrowSort', {
                active:
                  isUpdatedSort.updatedSortBy === sortByValues[1] &&
                  sortDir === 'ASC',
                visible: isUpdatedSort.updatedSortBy === sortByValues[1],
              })}
            />
          </li>

          <li
            className="table-row title"
            onClick={() => {
              handleClicks(sortByValues[2]);
            }}
          >
            {content.TableRow3Title}
            <span
              className={classnames('arrowSort', {
                active:
                  isUpdatedSort.updatedSortBy === sortByValues[2] &&
                  sortDir === 'ASC',
                visible: isUpdatedSort.updatedSortBy === sortByValues[2],
              })}
            />
          </li>

          <li
            className="table-row title"
            onClick={() => {
              handleClicks(sortByValues[3]);
            }}
          >
            {content.TableRow4Title}
            <span
              className={classnames('arrowSort', {
                active:
                  isUpdatedSort.updatedSortBy === sortByValues[3] &&
                  sortDir === 'ASC',
                visible: isUpdatedSort.updatedSortBy === sortByValues[3],
              })}
            />
          </li>

          <li
            className="table-row title"
            onClick={() => {
              handleClicks(sortByValues[4]);
            }}
          >
            {content.TableRow5Title}
            <span
              className={classnames('arrowSort', {
                active:
                  isUpdatedSort.updatedSortBy === sortByValues[4] &&
                  sortDir === 'ASC',
                visible: isUpdatedSort.updatedSortBy === sortByValues[4],
              })}
            />
          </li>
        </ul>

        {content.biz_total !== 0 && searchFlag === true && (
          <>
            {content.items.map((item, i) => {
              return <SingleAccordionTable key={i} content={item} index={i} />;
            })}
          </>
        )}

        {content.biz_total === 0 && (
          <Illustration
            addClass="address-transaction"
            image={require('../assets/images/icon-search.svg')}
            heading={content.illustrationHeading}
            description={content.illustrationDesc}
          />
        )}

        {searchFlag === false ||
          (content.biz_total !== 0 && content.total === 0 && (
            <Illustration
              addClass="address-transaction"
              image={require('../assets/images/icon-no-result.svg')}
              heading="No search result"
              description={
                <>
                  Please, try a different combination of <span>filters</span>
                </>
              }
            />
          ))}
      </Accordion>

      <div className="pagination-wrapper">
        <Pagination
          prevPageText={
            <img
              src={require('../assets/images/chevron-left.svg')}
              className="icon icon-grid"
              alt="left chevron"
            />
          }
          nextPageText={
            <img
              src={require('../assets/images/chevron-right.svg')}
              className="icon icon-grid"
              alt="right chevron"
            />
          }
          activePage={pageIndex}
          itemsCountPerPage={parseInt(pageSize)}
          totalItemsCount={pageTotalItems}
          pageRangeDisplayed={range}
          onChange={handlePageChange}
        />

        <div className="select-holder">
          <select id="item-length" onChange={getSetValue} value={pageSize}>
            <option value="10">10 per page</option>
            <option value="20">20 per page</option>
            <option value="30">30 per page</option>
            <option value="40">40 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default AccordionTable;
