import React, { useContext, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Form, FormGroup, InputGroup, Input } from 'reactstrap';
import axios from 'axios';
import classnames from 'classnames';

import response from '../helpers/responseChecker';
import { Context } from '../contexts/Context';
import auth from '../helpers/auth';
import { WHITELISTING_ENDPOINTS, MONITORING_ENDPOINTS } from '../helpers/urls';
import { RESPONSE_CODES } from '../helpers/httpCodes';

const SearchBar = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {
    searchState,
    searchTerm,
    activeModule,
    updateSortBy,
    updateSortDir,
    updatePageIndex,
    updateSearchTerm,
    updateSearchState,
    updateIsLoadingState,
    updateSearchFlag,
  } = useContext(Context);

  const history = useHistory();
  const [searchErrorMessage, setSearchErrorMessage] = useState('');

  // get tokens stored in local storage
  let userID = null;
  if (typeof window !== `undefined`) {
    userID = localStorage.getItem('user_id');
  }

  // SAVE TYPE INPUT VALUE TO STATE VARIABLES
  const handleData = (e) => {
    updateSearchTerm(e.target.value);
  };

  const endpoint =
    activeModule === 'Whitelisting'
      ? WHITELISTING_ENDPOINTS.search
      : MONITORING_ENDPOINTS.search;
  const itemType = activeModule === 'Whitelisting' ? 'address' : 'transaction';

  // SEARCH AN ADDRESS
  const handleSubmit = (e) => {
    e.preventDefault();

    if (searchTerm === '') {
      setSearchErrorMessage(
        'Incorrect or unsupported asset. Please review and submit again.'
      );
    } else {
      updateIsLoadingState(true);
      e.preventDefault();
      axios
        .get(
          endpoint + '&user_id=' + userID + '&' + itemType + '=' + searchTerm,
          auth.getAuthConfig()
        )
        .then(({ data }) => {
          const validator = response.validate(data.code[0], data.message[0]);
          if (validator) {
            setSearchErrorMessage('');
            updateSortBy('CreateTimestamp');
            updateSortDir('DESC');
            updatePageIndex(1);
            updateSearchState(!searchState);
            updateIsLoadingState(false);
            updateSearchFlag(true);
            console.log('it ran');
          } else {
            console.log(validator);
          }
        })
        .catch((error) => {
          if (
            error.response.data.code[0] === RESPONSE_CODES.httpServerErrCode
          ) {
            console.log('Server error...');
          } else if (
            error.response.data.code[0] === RESPONSE_CODES.httpBadRequest
          ) {
            updateIsLoadingState(false);
            setSearchErrorMessage(
              'Incorrect or unsupported asset. Please review and submit again.'
            );
            updateSearchFlag(false);
            console.log(error.response);
          } else if (
            error.response.data.code[0] === RESPONSE_CODES.httpAuthErrCode
          ) {
            history.push('/login');
          }
        });
    }
  };

  return (
    <div className="search-bar-component">
      <div className="container">
        <div className="search-wrapper">
          <Form className="search-form" onSubmit={handleSubmit}>
            <FormGroup
              className={classnames('customer-id-input', {
                invalid: searchErrorMessage !== '',
              })}
            >
              <InputGroup>
                <Input
                  onKeyPress={handleData}
                  onChange={handleData}
                  placeholder="Search"
                  className="search-input"
                />

                <button type="submit" className="btn-search">
                  <img
                    src={require('../assets/images/search.svg')}
                    alt="search icon"
                  />
                </button>
              </InputGroup>
              {searchErrorMessage && (
                <p className="error-message">{searchErrorMessage}</p>
              )}
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SearchBar);
