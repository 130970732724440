import React from 'react'
import { Container } from 'reactstrap'

//components
import Header from '../components/header'


const global = () => {
  return (
    <>
      <Header />
      <Container>
        <h1 className="section-title">Button</h1>
        <button className="btn-custom btn-outline-blue">Save</button>
        <button className="btn-custom btn-blue">Edit</button>
        <h2 className="section-title">Case Management</h2>
        <h2 className="title">Distribution</h2>
        <p className="subtitle">By Risk</p>
        <p className="label title">User Name</p>
        <h2 className="section-title">Accordion Table</h2>
        {/* <AccordionTable content={AccordionTableContent} /> */}
      </Container>
    </>
  )
}

export default global
